import React from "react"
import { TransitionPortal } from "gatsby-plugin-transition-link"
import { connect } from "react-redux"
import Helmet from "react-helmet"

import IconClose from "@images/icon-close.svg"
import Nav from "@components/nav"
import Footer from "@components/footer"
import { closeModal } from "@store/reducers"

const Layout = ({ children, modal, dispatch, location }) => {
  const closeModalFunc = () => dispatch(closeModal())

  return (
    <div className="layout">
      <Helmet>
        <link rel="canonical" href={location.href} />
        <meta property="og:url" content={location.href} />
      </Helmet>

      <Nav />

      <div className={`modal modal--${modal.status}`}>
        <div className="modal__overlay" />
        <div className="modal__content">
          <div className="modal__close" onClick={closeModalFunc}>
            <IconClose />
          </div>
          {modal.content}
        </div>
      </div>

      <div className="content-wrapper">
        <main>{children}</main>
      </div>


      <Footer />
      <TransitionPortal>
        <div id="transition-cover" />
      </TransitionPortal>
    </div>
  )
}

const mapStateToProps = state => state

export default connect(mapStateToProps)(Layout)
