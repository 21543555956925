import React from "react"
import get from "lodash.get"
import omit from "lodash.omit"
import Helmet from "react-helmet"
import marked from "marked"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

import Link from "@components/swipe-link"
import SEO from "@components/seo"
import Image from "@components/image"
import Markdown from "@components/markdown"
import PostCard from "@components/post-card"
import Enter from "@components/animate-entry"
import { makePath } from "@utils/url"
import { ogUrl } from "@utils/image"
import IconArrowLeft from "@images/icon-arrow-left.svg"
import IconMarker from "@images/icon-marker.svg"
import Button from "@components/button"
import Video, { getVideoFormat } from "@components/video"
import { employeeJsonLd } from "@templates/leader"

import "./index.scss"
import ImageCarousel from "../../components/image-carousel"

export const projectJsonLd = project => {
  const employee = employeeJsonLd(get(project, "team.content[0]"))

  return {
    "@context": "https://schema.org/",
    "@type": "Project",
    slogan: get(project, "tagline.tagline"),
    member: employee && omit(employee, "@context"),
    description:
      marked(get(project, "mission.description.description", "")) || undefined,
    name: project.name,
    image: get(project, "heroImage.file.url", undefined),
  }
}

const Project = ({ pageContext: { project, jobs, posts } }) => {
  const {
    uid,
    name,
    status,
    tagline,
    heroImage,
    video,
    mission,
    technology,
    outcomes,
    team,
    quote,
    pageDescription,
    pageName,
    facts,
    cta,
    childContentfulProjectAdditionalContentTextNode,
  } = project

  const sections = { mission, technology, outcomes, team, posts }

  const numbers = Object.keys(sections)
    .filter(key =>
      sections[key] instanceof Array ? sections[key].length > 0 : sections[key]
    )
    .reduce(
      (obj, key, index) => ({
        ...obj,
        [key]: `0${index + 1}`,
      }),
      {}
    )

  const downloadLinks = section => {
    if (!section || !section.downloads || !section.downloads.length) {
      return null
    }

    return (
      <Enter>
        <div className="u-mt32 project__download-links">
          {section.downloads.map(download => (
            <Link
              className="button button-tertiary"
              key={download.title}
              to={download.file.url}
              download={download.file.url}
            >
              Download {download.title}
            </Link>
          ))}
        </div>
      </Enter>
    )
  }

  return (
    <section className={`project ${uid}`}>
      <SEO
        title={pageName}
        description={pageDescription}
        image={ogUrl(heroImage.file.url)}
      />
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(projectJsonLd(project))}
        </script>
      </Helmet>
      <nav className="project-nav u-layout-max-width">
        <Link
          reverse
          to="/projects"
          className="button button-tertiary button-tertiary-left"
        >
          <div className="button-tertiary__content-wrap">
            <IconArrowLeft className="button-tertiary__icon" />
            <span className="button-tertiary__text">All Projects</span>
          </div>
        </Link>
      </nav>

      <header className="project-hero">
        <Enter>
          <div className="project-hero__content u-layout-max-width">
            <div className="project-hero__header">
              <h1 className="project-hero__name">{name}</h1>
              <h4 className="project-hero__status">{status} Project</h4>
            </div>
            <h3 className="project-hero__desc">{tagline.tagline}</h3>
          </div>
        </Enter>
        <Enter>
          <div className="project-hero__image-wrap">
            <Image
              className="project-hero__image"
              {...heroImage}
              lazyDims={{ width: "100vw" }}
            />
          </div>
        </Enter>
      </header>

      {facts && facts.length > 0 && (
        <section className="facts">
          <div className="facts__content u-layout-max-width">
            {facts.map(fact => (
              <article className="facts__fact" key={fact.name}>
                <Enter>
                  <div className="facts__header">
                    <h3 className="facts__value">{fact.value}</h3>

                    <h4 className="facts__name">{fact.name} </h4>
                  </div>
                </Enter>
                <Enter>
                  <Markdown
                    className="facts__desc"
                    source={fact.description.description}
                  />
                </Enter>
              </article>
            ))}
          </div>
        </section>
      )}

      {quote && quote.text && (
        <Enter>
          <section className="large-quote u-layout-max-width">
            <div className="large-quote__content">
              <blockquote className="large-quote__blockquote">
                "{quote.text.text}"
              </blockquote>
              <div className="large-quote__quotee">
                <h4 className="large-quote__quotee-name">
                  {quote.speaker.name}
                </h4>
                <span className="large-quote__quotee-role">
                  {quote.speaker.role}
                </span>
              </div>
            </div>
          </section>
        </Enter>
      )}

      {video && video.video && video.video.file && (
        <Enter>
          <section className="video-wrap u-layout-max-width">
            <Video
              {...video.video.file}
              title={video.video.title}
              image={video.posterImage}
            />
          </section>
        </Enter>
      )}

      {/* numbered article */}
      {mission && (
        <section
          className={`numbered-article numbered-article-half-width u-layout-max-width ${
            mission.image ? "numbered-article-with-image" : ""
          }`}
        >
          <div className="numbered-article__content">
            <div className="numbered-article__content-upper">
              <Enter>
                <h4 className="numbered-article__category">
                  <span className="numbered-article__number">
                    {numbers.mission}
                  </span>
                  Mission
                </h4>
              </Enter>
              <Enter>
                <h3 className="numbered-article__project-title">
                  {mission.title}
                </h3>
              </Enter>
              <Enter>
                <Markdown source={mission.description.description} />
              </Enter>
              {downloadLinks(mission)}
            </div>
            {mission.image && !getVideoFormat(mission.image.file.url) ? (
              <Enter>
                <figure className="numbered-article__image-wrap">
                  <Image
                    className="numbered-article__image"
                    {...mission.image}
                    lazy
                  />
                </figure>
              </Enter>
            ) : null}
            {mission.cta && (
              <Enter>
                <Button
                  type="tertiary"
                  className="u-mt25"
                  to={mission.cta.destination}
                  icon
                >
                  {mission.cta.linkText}
                </Button>
              </Enter>
            )}
            {mission.video && mission.video.video ? (
              <Video
                {...mission.video.video.file}
                className="numbered-article__video"
                title={mission.video.video.file.title}
                image={mission.video.posterImage}
              />
            ) : null}
          </div>
        </section>
      )}

      {/* numbered article with image */}
      {technology && (
        <section className="numbered-article numbered-article-with-image u-layout-max-width">
          <div
            className={`numbered-article__content ${
              mission && mission.image
                ? "numbered-article__content--reverse"
                : ""
            }`}
          >
            <div className="numbered-article__content-upper">
              <Enter>
                <h4 className="numbered-article__category">
                  <span className="numbered-article__number">
                    {numbers.technology}
                  </span>
                  Technology
                </h4>
              </Enter>
              <Enter>
                <h3 className="numbered-article__title">{technology.title}</h3>
              </Enter>
              <Enter>
                <Markdown source={technology.description.description} />
              </Enter>
              {downloadLinks(technology)}
              {technology.cta && (
                <Enter>
                  <Button
                    type="tertiary"
                    className="u-mt25"
                    to={technology.cta.destination}
                    icon
                  >
                    {technology.cta.linkText}
                  </Button>
                </Enter>
              )}
              {technology.video && (
                <div className="technologyVideo">
                  <h3>{technology.video.video.title}</h3>
                  <Enter>
                    <Video
                      {...technology.video.video.file}
                      className="numbered-article__video"
                      title={technology.video.video.title}
                      image={technology.video.posterImage}
                    />
                  </Enter>
                </div>
              )}
            </div>
            {technology.image ? (
              <Enter>
                <figure className="numbered-article__image-wrap">
                  <Image
                    className="numbered-article__image"
                    {...technology.image}
                    lazy
                  />
                </figure>
              </Enter>
            ) : null}
          </div>
        </section>
      )}

      {/* Logo slider */}
      {technology && technology.imageSet !== null && (
        <section className="carousel-section-new numbered-article numbered-article-with-image u-layout-max-width">
          <div className="numbered-article__content">
            <ImageCarousel imageSet={technology.imageSet} />
          </div>
        </section>
      )}

      {/* Outcomes section */}
      {outcomes && (
        <section className="numbered-article numbered-article-with-image u-layout-max-width">
          <div
            className={`numbered-article__content ${
              mission && mission.image
                ? "numbered-article__content--reverse"
                : ""
            }`}
          >
            <div className="numbered-article__content-upper">
              <Enter>
                <h4 className="numbered-article__category">
                  <span className="numbered-article__number">
                    {numbers.outcomes}
                  </span>
                  Services
                </h4>
              </Enter>
              <Enter>
                <h3 className="numbered-article__title">{outcomes.title}</h3>
              </Enter>
              <Enter>
                <Markdown source={outcomes.description.description} />
              </Enter>
              {downloadLinks(outcomes)}
              {/* {outcomes.cta && (
                <Enter>
                  <Button
                    type="tertiary"
                    className="u-mt25"
                    to={outcomes.cta.destination}
                    icon
                  >
                    {outcomes.cta.linkText}
                  </Button>
                </Enter>
              )} */}
            </div>
            {outcomes.image ? (
              <Enter>
                <figure className="numbered-article__image-wrap">
                  <Image
                    className="numbered-article__image"
                    {...outcomes.image}
                    lazy
                  />
                </figure>
              </Enter>
            ) : null}
          </div>
        </section>
      )}

      {/* Logo slider */}
      {outcomes && outcomes.imageSet !== null && (
        <section className="carousel-section-new numbered-article numbered-article-with-image u-layout-max-width">
          <div className="numbered-article__content">
            <ImageCarousel imageSet={outcomes.imageSet} />
          </div>
          <div className="numbered-article__content">
            {outcomes.cta && (
              <Enter>
                <Button
                  type="tertiary"
                  className="u-mt25"
                  to={outcomes.cta.destination}
                  icon
                >
                  {outcomes.cta.linkText}
                </Button>
              </Enter>
            )}
          </div>
        </section>
      )}

      {/* numbered article team */}
      {team && (
        <section className="numbered-article numbered-article-team numbered-article-full-width">
          <div className="numbered-article__content u-layout-max-width">
            <Enter>
              <h4 className="numbered-article__category">
                <span className="numbered-article__number">{numbers.team}</span>
                Team
              </h4>
            </Enter>
            <Enter>
              <h3>{team.title}</h3>
            </Enter>
            <Enter>
              <div className="numbered-article__markdown">
                <Markdown source={team.description.description} />
              </div>
            </Enter>
            {team.content &&
              team.content.map(employee => (
                <article className="numbered-article__team" key={employee.name}>
                  <Enter>
                    <div className="numbered-article__team-profile numbered-article__team-profile--upper">
                      <h4 className="numbered-article__team-name">
                        {employee.name}
                      </h4>
                      <em className="numbered-article__team-role">
                        {employee.role}
                      </em>
                    </div>
                  </Enter>
                  {employee.image ? (
                    <Enter>
                      <figure className="numbered-article__team-figure">
                        <Image
                          className="numbered-article__team-image"
                          {...employee.image}
                          w="400"
                          h="400"
                          lazy
                        />
                      </figure>
                    </Enter>
                  ) : null}
                  <div className="numbered-article__team-profile numbered-article__team-profile--lower">
                    <Enter>
                      <h4 className="numbered-article__team-name">
                        {employee.name}
                      </h4>
                    </Enter>
                    <Enter>
                      <em className="numbered-article__team-role">
                        {employee.role}
                      </em>
                    </Enter>
                    <Enter>
                      <Markdown
                        className="numbered-article__markdown"
                        source={employee.bio.bio}
                      />
                    </Enter>
                  </div>
                </article>
              ))}
          </div>
        </section>
      )}

      {cta && (
        <Enter>
          <section className="project-cta">
            <div className="project-cta__content u-layout-max-width">
              <h1 className="h3">{cta.titleText}</h1>
              <div className="project-cta__details">
                <Markdown
                  className="project-cta__desc"
                  source={cta.description.description}
                  renderers={{
                    link: props => {
                      return (
                        <Button type="tertiary" icon to={props.href}>
                          {props.children}
                        </Button>
                      )
                    },
                  }}
                />
                <Button type="tertiary" to={cta.destination} icon>
                  {cta.linkText}
                </Button>
              </div>
            </div>
          </section>
        </Enter>
      )}

      {posts && posts.length > 0 && (
        <section className="blog-cards u-layout-max-width">
          <h4 className="numbered-article__category">
            <span className="numbered-article__number">{numbers.posts}</span>
            Learn More
          </h4>
          <h3 className="numbered-article__title">
            Check out our blog and publications for more in-depth details about{" "}
            {name}
          </h3>

          <article className="blog-card">
            {posts.slice(0, 4).map((post, index) => (
              <Enter delay={index * 150} key={post.title}>
                <PostCard post={post} />
              </Enter>
            ))}
            {posts.length > 4 ? (
              <Button type="secondary" to={makePath.projectBlog(project)}>
                View All News
              </Button>
            ) : null}
          </article>
        </section>
      )}

      {jobs && jobs.length > 0 && (
        <section className="project-jobs">
          <div className="project-jobs__content u-layout-max-width">
            <Enter>
              <div className="project-jobs__header">
                <h1 className="h3">{name} Job Openings</h1>
                <p className="project-jobs__location">
                  <IconMarker className="project-jobs__icon" />
                  Sunnyvale, CA
                </p>
              </div>
            </Enter>
            <Enter>
              <ul className="project-jobs__list">
                {jobs.slice(0, 7).map(job => (
                  <li className="project-jobs__list-item" key={job.title}>
                    <Link
                      className="project-jobs__link"
                      to={makePath.job(job, project.name)}
                      onClick={() => {
                        trackCustomEvent({
                          category: "Project Job Link",
                          action: "Click",
                          label: `${project.name} Job - ${makePath.job(
                            job,
                            project.name
                          )}`,
                        })
                      }}
                    >
                      {job.title}
                    </Link>
                  </li>
                ))}
                {jobs.length > 7 && (
                  <Button
                    type="tertiary"
                    to={makePath.projectCareers(project)}
                    icon
                    onClick={() => {
                      trackCustomEvent({
                        category: "Project Jobs Link",
                        action: "Click",
                        label: `Link from ${
                          project.name
                        } - ${makePath.projectCareers(project)}`,
                      })
                    }}
                  >
                    View all {jobs.length} openings
                  </Button>
                )}
              </ul>
            </Enter>
          </div>
        </section>
      )}

      {childContentfulProjectAdditionalContentTextNode && (
        <Enter>
          <section className="additional-content u-layout-max-width">
            <Markdown
              source={
                childContentfulProjectAdditionalContentTextNode.additionalContent
              }
            />
          </section>
        </Enter>
      )}
    </section>
  )
}

export default Project
