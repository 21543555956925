import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import Markdown from "@components/markdown"
import SEO from "@components/seo"
import Image from "@components/image"
import "./index.scss"

function encode(data) {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&")
}

// eslint-disable-next-line
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const ContactAirbus = () => {
    const { contentfulPage } = useStaticQuery(graphql`
    query ContactAirbus {
      contentfulPage(uid: { eq: "contact-airbus-utm" }) {
        pageName
        pageDescription
        sections {
          ... on ContentfulPageSection {
            uid
            title
            subtitle
            description {
                description
            }
            image {
              title
              file {
                url
              }
            }
            content {
              ... on ContentfulLinkGroup {
                name
                links {
                  text
                  destination
                  description {
                    description
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [company, setCompany] = useState("")
    const [position, setPosition] = useState("")
    const [message, setMessage] = useState("")
    const [errors, setErrors] = useState([])
    const [emailError, setEmailError] = useState(null)
    const [status, setStatus] = useState("unsent")

    const { sections, pageName, pageDescription } = contentfulPage
    const contactAirbusSection = sections.find(sect => sect.uid === "contact-airbus-inquires");

    const fields = { firstName, lastName, email, phone, company, position, message }
    const missing = ["firstName", "lastName", "email", "company", "message"].filter(k => !fields[k].length)

    const onSubmit = event => {
        event.preventDefault()

        if (missing.length) {
            setErrors(missing)
            return
        }

        if (!emailRegex.test(email)) {
            setErrors(["email"])
            return
        }

        const params = Object.keys(fields).reduce(
            (obj, key) => ({
                ...obj,
                [key]: fields[key],
            }),
            {}
        )

        setStatus("sending")

        const form = event.target
        const data = {
            "form-name": form.getAttribute("name"),
            ...params,
        }

        const body = encode(data)

        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body,
        })
            .then(() => {
                setStatus("sent")
                setFirstName("")
                setLastName("")
                setEmail("")
                setPhone("")
                setCompany("")
                setPosition("")
                setMessage("")
                trackCustomEvent({
                    category: "Contact Form",
                    action: "Submission",
                    label: "Contact Form Submissions",
                })
            })
            .catch(err => {
                setEmailError(err.message)
                setStatus("failed")
            })
    }

    return (
        <section className="contact-airbus u-layout-max-width">
            <SEO title={pageName} description={pageDescription} />

            <article className="contact-airbus-header">
                <div className="contact-airbus-text u-layout-max-width">
                    <h1>We're here to help</h1>
                    <p>Fill out the form and one of our experts can answer all your questions about our UTM platform and services.</p>
                </div>
            </article>

            {/* form */}
            <article className="contact-airbus-form">
                <div className="contact-form u-layout-max-width">
                    {status === "failed" ? (
                        <p className="has-error">Message failed to send: {emailError}.</p>
                    ) : null}
                    <form
                        className="contact-form__form"
                        name="contact-airbus"
                        method="post"
                        data-netlify="true"
                        data-netlify-honeypot="bot-field"
                        onSubmit={onSubmit}
                    >
                        <input type="hidden" name="form-name" value="contact-airbus" />
                        
                        <article className="contact-form__row">
                            <input
                                className={errors.includes("firstName") ? "has-error" : ""}
                                type="text"
                                value={firstName}
                                name="firstName"
                                onChange={({ target }) => {
                                    setFirstName(target.value)
                                    setErrors([])
                                    setEmailError(null)
                                    setStatus("unsent")
                                }}
                                placeholder="First Name *"
                            />

                        </article>

                        <article className="contact-form__row">
                            <input
                                className={errors.includes("lastName") ? "has-error" : ""}
                                type="text"
                                value={lastName}
                                name="lastName"
                                onChange={({ target }) => {
                                    setLastName(target.value)
                                    setErrors([])
                                    setEmailError(null)
                                    setStatus("unsent")
                                }}
                                placeholder="Last Name *"
                            />
                        </article>

                        <article className="contact-form__row">
                            <input
                                className={errors.includes("email") ? "has-error" : ""}
                                type="email"
                                name="email"
                                value={email}
                                onChange={({ target }) => {
                                    setEmail(target.value)
                                    setErrors([])
                                    setEmailError(null)
                                    setStatus("unsent")
                                }}
                                placeholder="Email *"
                            />
                        </article>

                        <article className="contact-form__row">
                            <input
                                className={errors.includes("phone") ? "has-error" : ""}
                                type="tel"
                                name="phone"
                                value={phone}
                                onChange={({ target }) => {
                                    setPhone(target.value)
                                    setErrors([])
                                    setEmailError(null)
                                    setStatus("unsent")
                                }}
                                placeholder="Phone "
                            />
                        </article>

                        <article className="contact-form__row">
                            <input
                                className={errors.includes("company") ? "has-error" : ""}
                                type="text"
                                name="company"
                                value={company}
                                onChange={({ target }) => {
                                    setCompany(target.value)
                                    setErrors([])
                                    setEmailError(null)
                                    setStatus("unsent")
                                }}
                                placeholder="Company *"
                            />
                        </article>

                        <article className="contact-form__row">
                            <input
                                className={errors.includes("position") ? "has-error" : ""}
                                type="text"
                                name="position"
                                value={position}
                                onChange={({ target }) => {
                                    setPosition(target.value)
                                    setErrors([])
                                    setEmailError(null)
                                    setStatus("unsent")
                                }}
                                placeholder="Position "
                            />
                        </article>

                        <article className="contact-form__row">
                            <textarea
                                className={errors.includes("message") ? "has-error" : ""}
                                type="text"
                                name="message"
                                value={message}
                                onChange={({ target }) => {
                                    setMessage(target.value)
                                    setErrors([])
                                    setEmailError(null)
                                    setStatus("unsent")
                                }}
                                placeholder="What can we do for you *"
                            />
                        </article>

                        <input
                            className={`button button-primary u-mb0 ${missing.length ? "disabled" : ""
                                }`}
                            type="submit"
                            value={status === "sending" ? "Sending..." : "Submit"}
                        />
                        {status === "sent" ? <p className="contact-utm-response">Your information was successfully submitted. A member of the Airbus UTM Team will get back to you. </p> : null}
                    </form>
                </div>
            </article>

            <article className="contact-airbus-inquiry">
                <div className='u-layout-max-width'>
                    <figure className="contact-inquiry-image-wrap">
                        <Image className="airbus-image" {...contactAirbusSection.image} lazy alt={contactAirbusSection.title} />
                    </figure>
                    <Markdown
                        source={contactAirbusSection.description.description} />
                </div>
            </article>

        </section>
    )
}

export default ContactAirbus
