import marked from "marked"

const months = [
  "Jan",
  "Feb",
  "March",
  "April",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
]

export const yearMonthDate = dateString =>
  dateString.split("-").map(n => parseInt(n))

export const formatDate = dateString => {
  const [year, month, date] = yearMonthDate(dateString)

  return `${months[month - 1]} ${date}, ${year}`
}

export const monthYear = dateString => {
  const [year, month] = yearMonthDate(dateString)

  return `${months[month - 1]} | ${year}`
}

export const truncateToCharLimitByWord = (paragraph, limit) => {
  let truncated = ""
  const reversedWords = paragraph.split(" ").reverse()

  while ((limit ? truncated.length < 175 : true) && reversedWords.length > 0) {
    truncated = `${truncated} ${reversedWords.pop()}`
  }

  truncated = truncated.trim()

  if (!limit || truncated.length < limit) {
    return truncated
  } else {
    return ["!", "?", ".", ",", ";"].includes(truncated[truncated.length - 1])
      ? truncated.slice(0, -1).concat("…")
      : truncated.concat("…")
  }
}

export const removeHtml = html => html.replace(/<[^>]*>?/gm, "")

export const insertApostrophes = text => text.replace(/&#39;/gm, "'")

export const generateTeaser = (markdown, truncateLength) => {
  const md = marked(markdown.split("\n")[0])
  const firstP = insertApostrophes(removeHtml(md || ""))

  return truncateToCharLimitByWord(firstP, truncateLength) || ""
}

export const makeLocationString = loc =>
  loc.replace("California, United States", "CA")

export const removeEnd = (string, end = ".") =>
  string.slice(string.length - end.length) === end
    ? string.slice(0, string.length - end.length)
    : string
