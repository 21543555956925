import React, { useState, useEffect } from "react"
import UpArrow from "@images/icon-arrow-up.svg"
import "./index.scss"

const ScrollToTop = () => {
  const [showTopBtn, setShowTopBtn] = useState(false)
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true)
      } else {
        setShowTopBtn(false)
      }
    })
  }, [])
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }
  return (
    <div className="top-to-bottom">
      {" "}
      {showTopBtn && (
        <div className="icon-position icon-style" onClick={goToTop}>
          <UpArrow />
        </div>
      )}{" "}
    </div>
  )
}
export default ScrollToTop
