import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import get from "lodash.get"
import omit from "lodash.omit"
import Helmet from "react-helmet"

import SEO from "@components/seo"
import Link from "@components/swipe-link"
import { makePath } from "@utils/url"
import Stagger from "@components/animate-row-entry"
import Enter from "@components/animate-entry"
import LazyBg from "@components/lazy-bg"
import { imageUrl } from "@utils/image"
import { projectJsonLd } from "@templates/project"

import "./index.scss"
import Filter from "../../components/filter"

const statusOrder = ["Active", "Previous"]

const Projects = () => {
  const data = useStaticQuery(graphql`
    query projects {
      allContentfulProject(sort: {fields: status, order: ASC}) {
        nodes {
          uid
          name
          status
          tagline {
            tagline
          }
          heroImage {
            title
            file {
              url
            }
          }
        }
      }
      contentfulPage(uid: { eq: "projects" }) {
        pageName
        pageDescription

        sections {
          ... on ContentfulPageSection {
            title
            description {
              description
            }
            cta {
              linkText
              destination
              description {
                description
              }
            }
            content {
              ... on ContentfulEmployee {
                id
                name
                role
                bio {
                  bio
                }
                image {
                  file {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const [selectedStatusIndex, selectStatusIndex] = useState(0)

  const projects = get(data, "allContentfulProject.nodes", [])
  const { contentfulPage } = data

  const { pageName, pageDescription, sections } = contentfulPage
  const { title, description, cta } = sections[0]

  const projectStatuses = projects.map(p => p.status)
  const statuses = ["All"].concat(
    statusOrder.filter(status => projectStatuses.includes(status))
  )

  const jsonLd = {
    "@context": "https://schema.org/",
    "@graph": projects.map(project => omit(projectJsonLd(project), "@context")),
  }

  return (
    <section className="projects">
      <SEO title={pageName} description={pageDescription} />
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
      </Helmet>
      <article className="projects__header u-layout-max-width">
        <Enter>
          <div className="projects__header-left">
            <h1>{title}</h1>
            <p className="body-large projects__header-desc">
              {description.description}
            </p>
          </div>
        </Enter>
        <Enter>
          <div className="projects__header-right">
            <Filter 
              items={statuses}
              selectedIndex={selectedStatusIndex}
              onItemClick={(_, index) => {
                selectStatusIndex(index)
              }}
            />
          </div>
        </Enter>
      </article>
      <Stagger delay={150}>
        <article className="projects__gallery u-layout-max-width">
          {projects
            .filter(
              project =>
                statuses[selectedStatusIndex] === "All" ||
                statuses[selectedStatusIndex] === project.status
            )
            .map(project => {
              return (
                <Link
                  className="projects__project"
                  key={project.name}
                  to={makePath.project(project)}
                >
                  <div className="projects__info">
                    <div className="projects__name-wrap">
                      <p className="label label-bold projects__name">
                        {project.name}
                      </p>
                    </div>
                    <p className="label projects__status">
                      {project.status}
                    </p>
                    <h1 className="projects__tagline">
                      {project.tagline.tagline}
                    </h1>

                  </div>

                  <LazyBg
                    className="projects__bg"
                    bg={`url(${imageUrl(project.heroImage.file.url, {
                      q: 85,
                      w: 1300,
                    })})`}
                  />
                </Link>
              )
            })}
        </article>
      </Stagger>
      <article className="cta-footer cta-footer--gray">
        <div className="cta-footer__content u-layout-max-width">
          <h3 className="cta-footer__title cta-footer__title--max-width">
            {cta.description.description}
          </h3>
          <Link to={cta.destination} className="button button-primary">
            {cta.linkText}
          </Link>
        </div>
      </article>
    </section>
  )
}

export default Projects
