import React from "react"

import SEO from "@components/seo"
import Button from "@components/button"

import "./index.scss"

const NotFoundPage = () => {
  return (
    <section className="page-not-found u-layout-max-width">
      <SEO title="404: Not found" />
      <h1>Not found</h1>
      <p>This route doesn't exist!</p>
      <Button type="tertiary" back icon to="/">
        Back Home
      </Button>
    </section>
  )
}

export default NotFoundPage
