import React from 'react'

import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
} from 'react-share'

import "./Share.scss";

const ShareButtons = ({ title, url }) => {

  return (
    <div>
      <FacebookShareButton url={url} quote={title}>
        <FacebookIcon size={40} round={true} />
      </FacebookShareButton>

      <TwitterShareButton url={url} title={title}>
        <TwitterIcon size={40} round={true} />
      </TwitterShareButton>

      <LinkedinShareButton url={url} >
        <LinkedinIcon size={40} round={true} />
      </LinkedinShareButton>
    </div>
  )

}
export default ShareButtons