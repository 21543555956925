import React from "react"

import { imageUrl } from "@utils/image"
import ConditionalDiv from "@components/conditional-div"

const endsIn = (string, ending) =>
  string.slice(string.length - ending.length) === ending

export const getVideoFormat = filename => {
  const videoFormats = ["mp4", "m4p", "ogg", "avi", "mov"]
  return videoFormats.find(format => endsIn(filename, `.${format}`))
}

const Video = props => {
  const { url, title, image, contentType, className } = props

  const videoFormat = contentType
    ? contentType.split("/")[1]
    : getVideoFormat(url)

  const videoProps = {
    src: image ? url : `${url}#t=0.5`,
    type: `video/${videoFormat}`,
  }

  if (videoFormat) {
    return (
      <ConditionalDiv wrap={!!className} wrapperClass={className}>
        <video
          width="100%"
          height="auto"
          controls
          className="video"
          preload="metadata"
          title={title}
          {...(image ? { poster: imageUrl(image.file.url) } : {})}
        >
          <source {...videoProps} />
          Your browser does not support the video tag.
        </video>
      </ConditionalDiv>
    )
  } else {
    console.log("Video format not recognized", url)
    return null
  }
}

export default Video
