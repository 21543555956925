import React, { useEffect } from "react"

import cn from "@utils/cn"
import { closestParentWithClass } from "@utils/dom"

import IconChevronDown from "@images/icon-chevron-down.svg"

import "./index.scss"

const Dropdown = ({
  items,
  selectedIndex,
  onTitleClick,
  onItemClick,
  isOpen,
  title,
}) => {
  // Check if click is outside of dropdown. If so, close it.
  const clickListener = ({ target }) => {
    const dropdownParent = closestParentWithClass(target, "dropdown")

    if (!dropdownParent && isOpen) {
      onTitleClick(true)
    }
  }

  // Attach clicklistener to whole document. Then unattach on unmount.
  useEffect(() => {
    document.addEventListener("mousedown", clickListener, false)

    return () => document.removeEventListener("mousedown", clickListener, false)
  })

  return (
    <article className={cn("dropdown", isOpen && "dropdown--open")}>
      <div className="dropdown__title" onClick={() => onTitleClick(isOpen)}>
        {typeof selectedIndex === "number"
          ? items[selectedIndex]
          : title || items[0]}
        <IconChevronDown className="dropdown__icon" />
      </div>

      <ul className="dropdown__list">
        {items.map((item, index) => (
          <li
            className={cn(
              "dropdown__item",
              selectedIndex === index && "dropdown__item--selected"
            )}
            key={item}
            onClick={() => onItemClick(item, index)}
          >
            <span>{item}</span>
          </li>
        ))}
      </ul>
    </article>
  )
}

export default Dropdown
