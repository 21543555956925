import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Link from "@components/swipe-link"
import SEO from "@components/seo"
import Image from "@components/image"
import Button from "@components/button"
import Enter from "@components/animate-entry"
import Markdown from "@components/markdown"

import "./index.scss"

const Memos = () => {
  const { contentfulPage } = useStaticQuery(graphql`
    query Memos {
      contentfulPage(uid: { eq: "memos" }) {
        pageDescription
        pageName
        sections {
          ... on ContentfulPageSection {
            uid
            id
            title
            description {
              description
            }
            image {
              title
              file {
                url
              }
            }
          }
        }
      }
    }
  `)

  const { sections, pageName, pageDescription } = contentfulPage
  const content = sections[0]

  return (
    <section className="memos">
      <SEO title={pageName} description={pageDescription} />

      <article className="memos-article hero-section">
        <div className="u-layout-max-width">
          <Enter>
            <h2 className="title">{content.title}</h2>
          </Enter>
        </div>

        <div className="memos-image">
          <Enter>
            <div className="project-hero__image-wrap">
              <Image
                className="project-hero__image"
                {...content.image}
                lazyDims={{ width: "100vw" }}
              />
            </div>
          </Enter>
        </div>

        <div className="u-layout-max-width">
          <Enter>
            <div className="memos-description">
              <Markdown source={content.description.description} />
            </div>
          </Enter>
        </div>
      </article>
    </section>
  )
}

export default Memos
