import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Markdown from "@components/markdown"
import SEO from "@components/seo"
import "./index.scss"


const Airbusutmtermsofservice = () => {
  const { contentfulPage } = useStaticQuery(graphql`
    query Airbusutmtermsofservice {
      contentfulPage(uid: { eq: "airbusutmtermsofservice" }) {
        pageName
        pageDescription
        sections {
          ... on ContentfulPageSection {
            uid
            title
			      subtitle
            imageSet {
              title
              file {
                url
              }
              description
            }
            image {
              title
              file {
                url
              }
            }
            content {
              ... on ContentfulPageSection {
                id
                title
                description {
                  description
                }
                cta {
                  linkText
                  destination
                }
                image {
                  file {
                    url
                  }
                }
              }
            }
            description {
                description
            }
            cta {
                linkText
                destination
            }
          }
        }
      }
    }
  `)

  const { sections, pageName, pageDescription } = contentfulPage
  const termServiceSection = sections.find(sect => sect.uid === "terms-of-service-content");

  return (
    <section className="terms-service u-layout-max-width">
      <SEO title={pageName} description={pageDescription} />
      <article className="terms-content">
        <div className='u-layout-max-width'>
          <h1>{termServiceSection.title}</h1>
          <Markdown
            source={termServiceSection.description.description}
          />
        </div>
      </article>
    </section>
  )
}

export default Airbusutmtermsofservice