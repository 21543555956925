import React from "react"
import DownArrow from "@images/icon-arrow-down.svg"

const ScrollToBottom = (props) => {
  const toBottom = () => {
    window.scrollTo({
      top: props.wayfinderDetail.current.offsetTop,
      behavior: 'smooth',
    });
  }

  return (
    <div className="top-to-btm" onClick={toBottom}>
      <DownArrow />
    </div>
  )
}

export default ScrollToBottom
