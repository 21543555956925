import React, { useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useDispatch } from "react-redux"
import get from "lodash/get"

import Markdown from "@components/markdown"
import SEO from "@components/seo"
import HeroSection from "@components/hero-section"
import Link from "@components/swipe-link"
import Image from "@components/image"
import Enter from "@components/animate-entry"
import Stagger from "@components/animate-row-entry"
import AcubedLifeProfile from "@components/acubed-life-profile"
import LazyBg from "@components/lazy-bg"
import { closeModal, openModal } from "@store/reducers"
import { imageUrl } from "@utils/image"

import "./index.scss"

const AcubedLife = () => {
  const dispatch = useDispatch()
  const { contentfulPage } = useStaticQuery(graphql`
    query AcubedLife {
      contentfulPage(uid: { eq: "acubed-life" }) {
        pageName
        pageDescription
        sections {
          ... on ContentfulPageSection {
            uid
            title
            image {
              title
              file {
                url
              }
            }
            description {
              description
            }
            content {
              ... on ContentfulBenefitGroup {
                id
                title
                benefits {
                  description
                  title
                  file {
                    url
                  }
                }
              }
              ... on ContentfulAcubedLifeEmployeeCard {
                id
                name
                role
                quoteSpeaker
                quote {
                  quote
                }
                profileText {
                  profileText
                }
                image {
                  title
                  file {
                    url
                  }
                }
              }
            }
            imageSet {
              title
              file {
                url
              }
            }
            cta {
              titleText
              destination
              linkText
              description {
                description
              }
            }
          }
        }
      }
    }
  `)

  useEffect(() => {
    return () => dispatch(closeModal())
  }, [dispatch])

  const { pageName, pageDescription, sections } = contentfulPage
  const hero = sections.find(sec => sec.uid === "acubed-life-hero")
  const benefits = sections.find(sec => sec.uid === "acubed-life-benefits")
  const lifestyle = sections.find(sec => sec.uid === "acubed-life-style")
  const workspace = sections.find(sec => sec.uid === "acubed-life-workspace")

  const profiles = lifestyle.content

  return (
    <div className="acubed-life">
      <SEO title={pageName} description={pageDescription} />

      {hero && (
        <div className="hero">
          <HeroSection
            title={hero.title}
            description={hero.description}
            image={hero.image}
          />
        </div>
      )}

      {benefits && (
        <div className="benefits">
          <div className="benefits__header u-layout-max-width">
            <div className="benefits__header-left">
              <Enter>
                <h2>{benefits.title}</h2>
              </Enter>
              <Enter>
                <Markdown
                  markdownClass="markdown--copy"
                  source={get(benefits, "description.description")}
                />
              </Enter>
            </div>
            {benefits.cta ? (
              <div className="benefits__header-right">
                <Enter animationClass="fade-in">
                  <h3>{get(benefits, "cta.description.description")}</h3>
                </Enter>
                <Link
                  className="button button-primary"
                  to={benefits.cta.destination}
                >
                  {benefits.cta.linkText}
                </Link>
              </div>
            ) : null}
          </div>

          <Enter>
            <div className="hero-section__image-wrap hero-section__image-wrap--acubed-life">
              <Image
                className="hero-section__image"
                {...benefits.image}
                w="1800"
                lazy
              />
            </div>
          </Enter>

          {benefits.content.map(benefitGroup => (
            <div
              className="benefits__benefit-group u-layout-max-width"
              key={benefitGroup.title}
            >
              <Enter>
                <h3 className="benefits__title">{benefitGroup.title}</h3>
              </Enter>
              <Stagger delay={200}>
                <div className="benefits__benefit-wrap">
                  {benefitGroup.benefits.map(benefit => (
                    <div className="benefits__benefit" key={benefit.title}>
                      <Image
                        className="benefits__benefit-icon"
                        {...benefit}
                        lazy
                      />
                      <h4 className="benefits__benefit-title">
                        {benefit.title}
                      </h4>
                      <p>{benefit.description}</p>
                    </div>
                  ))}
                </div>
              </Stagger>
            </div>
          ))}
        </div>
      )}
      {lifestyle && (
        <div className="lifestyle u-layout-max-width">
          <Enter>
            <h2 className="lifestyle__title">{lifestyle.title}</h2>
          </Enter>
          <Stagger delay={200}>
            <div className="lifestyle__profiles">
              {profiles.map(profile => (
                <div
                  onClick={() => {
                    dispatch(openModal(<AcubedLifeProfile {...profile} />))
                  }}
                  className="lifestyle__profile"
                  key={profile.name}
                >
                  <Image
                    className="lifestyle__img"
                    {...profile.image}
                    w="400"
                    h="400"
                  />
                  <div className="lifestyle__hover">
                    <p className="lifestyle__name">{profile.name}</p>
                    <p className="lifestyle__role">{profile.role}</p>
                  </div>
                </div>
              ))}
            </div>
          </Stagger>
        </div>
      )}

      {workspace && (
        <section className="workspace">
          <div className="workspace__content u-layout-max-width">
            <div className="workspace__limit">
              <Enter>
                <h2 className="workspace__title">{workspace.title}</h2>
              </Enter>
              <Enter>
                <p className="workspace__desc">
                  {workspace.description.description}
                </p>
              </Enter>
            </div>

            <article className="workspace__gallery">
              {workspace.imageSet.map(image => (
                <Enter key={image.file.url} animationClass="fade-in">
                  <LazyBg
                    className="workspace__img-wrap"
                    bg={`#fff url(${imageUrl(image.file.url, {
                      q: 85,
                      w: 700,
                    })}) center center/cover no-repeat`}
                  />
                </Enter>
              ))}
            </article>
          </div>
          <article className="cta-footer cta-footer--gray">
            <div className="cta-footer__content u-layout-max-width">
              <Enter>
                <h3 className="cta-footer__title cta-footer__title--large">
                  {workspace.cta.titleText}
                </h3>
              </Enter>
              <Enter>
                <p className="cta-footer__desc">
                  {workspace.cta.description.description}
                </p>
              </Enter>
              <Link
                to={workspace.cta.destination}
                className="button button-primary"
              >
                {workspace.cta.linkText}
              </Link>
            </div>
          </article>
        </section>
      )}
    </div>
  )
}

export default AcubedLife
