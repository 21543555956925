import { combineReducers } from "redux"

const setModal = (status, content) => ({
  type: "SET_MODAL",
  status,
  content,
})

const setBodyStyle = (key, value) => {
  if (document) {
    const body = document.getElementsByTagName("body")[0]
    body.style[key] = value
  }
}

// Closing - animate out, then empty content and close
// So it doesn't empty of content first, or not have a chance to animate.
export const closeModal = () => dispatch => {
  dispatch(setModal("closing"))

  setTimeout(() => {
    dispatch(setModal("closed"))
    setBodyStyle("overflow", "auto")
  }, 300)
}

// Opening - Just add content and open
export const openModal = content => dispatch => {
  dispatch(setModal("opening", content))

  setTimeout(() => {
    setBodyStyle("overflow", "hidden")
    dispatch(setModal("open", content))
  }, 300)
}

const modal = (state = {}, { type, status, content }) => {
  switch (type) {
    case "SET_MODAL":
      return { status, content: content || state.content }
    default:
      return state
  }
}

export default combineReducers({
  modal,
})
