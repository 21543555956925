import React, { useRef, useState } from "react"

import IconChevronLeft from "@images/icon-chevron-left.svg"
import IconChevronRight from "@images/icon-chevron-right.svg"

import "./index.scss"

const QuoteCarousel = ({ quotes }) => {
  const [slideIndex, setSlideIndex] = useState(0)
  const [height, setHeight] = useState(null)

  const ref = useRef(null)

  if (ref && ref.current && !height) {
    const children = Array.from(ref.current.children)
    setHeight(
      Math.max(
        ...children.map(
          c =>
            (c &&
              c.getBoundingClientRect() &&
              c.getBoundingClientRect().height) ||
            0
        )
      )
    )
  }

  return (
    <section className="quote-carousel">
      <div className="quote-carousel__content">
        <div
          className="quote-carousel__nav"
          onClick={() => setSlideIndex((slideIndex + 1) % quotes.length)}
        >
          <IconChevronLeft />
        </div>
        <div
          ref={ref}
          className="quote-carousel__quotes"
          style={height ? { height: `${height}px` } : {}}
        >
          {quotes.map((quote, index) => (
            <article
              key={quote.id}
              className={`quote-carousel__quote ${
                index === slideIndex ? "active" : ""
              }`}
            >
              <hr className="quote-carousel__divider" />
              <h4 className="quote-carousel__publication">
                {quote.publication}
              </h4>
              <blockquote className="large-quote__blockquote">
                “{quote.text.text}”
              </blockquote>
            </article>
          ))}
        </div>
        <div
          className="quote-carousel__nav"
          onClick={() =>
            setSlideIndex(
              slideIndex - 1 < 0 ? quotes.length - 1 : slideIndex - 1
            )
          }
        >
          <IconChevronRight />
        </div>
      </div>
      <div className="quote-carousel__dots">
        {quotes.map((quote, index) => (
          <div
            className={`quote-carousel__dot ${
              index === slideIndex ? "active" : ""
            }`}
            key={index}
            onClick={() => setSlideIndex(index)}
          >
            &bull;
          </div>
        ))}
      </div>
    </section>
  )
}

export default QuoteCarousel
