import React from "react"
import get from "lodash.get"
import marked from "marked"
import Helmet from "react-helmet"

import Link from "@components/swipe-link"
import SEO from "@components/seo"
import Markdown from "@components/markdown"
import Image from "@components/image"
import Enter from "@components/animate-entry"
import { generateTeaser } from "@utils/string"
import IconArrowLeft from "@images/icon-arrow-left.svg"
import IconChevronLeft from "@images/icon-chevron-left.svg"
import IconChevronRight from "@images/icon-chevron-right.svg"

import "./index.scss"

export const employeeJsonLd = member =>
  member
    ? {
        "@type": "Person",
        name: member.name,
        jobTitle: member.role,
        description: marked(get(member, "bio.bio", "")) || undefined,
      }
    : undefined

const Leader = ({ pageContext }) => {
  const { leader, next, prev } = pageContext

  const downloads = get(leader, "downloads") || []

  return (
    <div className="leader u-layout-max-width">
      <Link
        reverse
        to="/about-us"
        className="button button-tertiary button-tertiary-left button-tertiary-leader"
      >
        <div className="button-tertiary__content-wrap">
          <IconArrowLeft className="button-tertiary__icon" />
          <span className="button-tertiary__text">About Us</span>
        </div>
      </Link>

      <SEO
        title={`Acubed - ${leader.name}`}
        description={generateTeaser(leader.bio.bio, 160)}
      />

      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(employeeJsonLd(leader))}
        </script>
      </Helmet>

      <div className="leader__wrap">
        <div className="leader__header">
          <Enter>
            <div className="leader__header-image">
              <Image {...leader.image} title={leader.name} w="400" h="400" applyDefaultSettings={false} />
            </div>
          </Enter>
          <Enter delay={200}>
            <div className="leader__header-info">
              <h1 className="h4 u-upcase">{leader.name}</h1>
              <p className="leader__header-role">{leader.role}</p>
              {downloads.map(download => (
                <Link
                  key={download.file.url}
                  to={download.file.url}
                  className="button button-tertiary button-tertiary-leader-profile"
                >
                  <span className="button-tertiary__text">
                    Download {download.title}
                  </span>
                </Link>
              ))}
            </div>
          </Enter>
        </div>

        <Enter delay={400}>
          <div className="leader__content">
            <Markdown source={leader.bio.bio} />
          </div>
        </Enter>

        <Enter>
          <div className="leader__nav">
            <Link reverse className="leader__nav-button" to={prev}>
              <IconChevronLeft />
            </Link>
            <Link className="leader__nav-button" to={next}>
              <IconChevronRight />
            </Link>
          </div>
        </Enter>
      </div>
    </div>
  )
}

export default Leader
