import React from 'react';

const CookiePolicyPage = () => {
  return (
    <div className="cookie-policy-container u-layout-max-width">
      <h1 className="cookie-policy-title">Cookie Policy</h1>
      {/* Start of OneTrust cookie list */}
      <div id="ot-sdk-cookie-policy"></div>
      {/* End of the list of OneTrust cookies  */}
    </div>
  );
};

export default CookiePolicyPage;
