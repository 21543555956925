const imageUrl = (imageUrl, settings = {}, applyDefaultSettings = true) => {
  const acceptable = ["q", "fm", "fl", "f", "h", "w", "fit"]
  const defaults = { q: 85, fm: "jpg", fl: "progressive" }
  // If applyDefaultSettings is true, merge the default settings with the provided settings
  const finalSettings = applyDefaultSettings
    ? Object.assign({}, defaults, settings)
    : {}
  const keys = Object.keys(finalSettings).filter(k => acceptable.includes(k))

  // If the image URL ends with .gif, remove the "fm" (format) parameter
  if (imageUrl.toLowerCase().endsWith(".gif")) {
    keys.splice(keys.indexOf("fm"), 1)
  }

  // Return the image URL with the provided settings as a query string
  return keys.length
    ? `${imageUrl}?${keys
        .reduce((pairs, key) => [...pairs, `${key}=${finalSettings[key]}`], [])
        .join("&")}`
    : imageUrl
}

// A function to build the Open Graph image URL based on the provided URL
const ogUrl = url => {
  return imageUrl(url, {
    w: 1200,
    h: 630,
    q: 100,
    fit: "fill",
  })
}

module.exports.ogUrl = ogUrl
module.exports.imageUrl = imageUrl
