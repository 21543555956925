import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import kebabCase from "lodash.kebabcase"
import get from "lodash.get"

import Markdown from "@components/markdown"
import SEO from "@components/seo"
import Link from "@components/swipe-link"

import "./index.scss"

const Heading = title => props => {
  const { children, level } = props
  const text = get(children, "[0].props.children")
  const Header = React.createElement(`h${level}`, {
    children,
  })

  return (
    <div className="heading__wrapper">
      <div className="decoy" id={kebabCase(`${title} ${text.split(".")[1]}`)} />
      {Header}
    </div>
  )
}

const scrollTo = (hash, delay = 50, attempt = 0) => {
  return new Promise(resolve => {
    const target = hash.replace("#", "").replace("/", "")
    const el = document.getElementById(target)

    if (el) {
      el.scrollIntoView()
      // Lord knows why this seems necessary (intermittently)
      setTimeout(() => {
        const el2 = document.getElementById(target)
        if (el2) {
          el2.scrollIntoView()
        }
      }, 100)
    } else {
      if (attempt > 15) {
        resolve()
        return
      }

      setTimeout(() => {
        scrollTo(hash, delay, attempt + 1)
      }, 50)
    }
  })
}

const Terms = () => {
  const [target, setTarget] = useState(null)
  const { contentfulPage } = useStaticQuery(graphql`
    query Terms {
      contentfulPage(uid: { eq: "terms-and-privacy" }) {
        pageName
        pageDescription
        sections {
          ... on ContentfulPageSection {
            title
            description {
              description
            }
          }
        }
      }
    }
  `)

  const { sections, pageName, pageDescription } = contentfulPage

  useEffect(() => {
    if (window.location.hash) {
      setTarget(window.location.hash.replace("#", "").replace("/", ""))
    }
  })

  useEffect(() => {
    if (target) {
      scrollTo(target, 0)
    }
  }, [target])

  return (
    <section className="terms u-layout-max-width">
      <SEO title={pageName} description={pageDescription} />
      <div className="terms__top">
        <h1>A^3 by Airbus LLC</h1>
        <ul className="terms__list">
          {sections.map(section => (
            <li className="terms__list-item" key={section.title}>
              <a
                className="terms__list-item-link"
                href={`#document-${kebabCase(section.title)}`}
                onClick={() => {
                  setTarget(`#document-${kebabCase(section.title)}`)
                }}
              >
                {section.title}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className="terms__divider" />
      {sections.map(section => (
        <div className="terms__section" key={section.title}>
          <div className="heading__wrapper">
            <div
              className="decoy"
              id={`document-${kebabCase(section.title)}`}
            />
            <h2 className="terms__section-header">{section.title}</h2>
          </div>
          <Markdown
            source={section.description.description}
            renderers={{
              heading: Heading(section.title),
              link: props => {
                const { children, href } = props
                const text = get(children, "[0].props.children")
                const isInternal =
                  !href || href[0] === "#" || href.includes("#document")

                const to = isInternal
                  ? href
                    ? `#${href.split("#")[1]}`
                    : `#${kebabCase(section.title)}-${kebabCase(text)}`
                  : ""

                return !isInternal ? (
                  <Link to={href}>{children}</Link>
                ) : (
                  <a
                    href={to}
                    onClick={() => {
                      setTimeout(() => {
                        setTarget(to)
                      }, 100)
                    }}
                  >
                    {props.children}
                  </a>
                )
              },
            }}
          />
          <div className="terms__divider" />
        </div>
      ))}
    </section>
  )
}

export default Terms