import React from "react"
import Link from "gatsby-plugin-transition-link"
import { TimelineMax, Power1 } from "gsap"
import omit from "lodash.omit"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import { isInternal } from "@utils/url"

import "./index.scss"

const animate = node => ({ length }, { reverse, color }) => {
  const transitionCover = document.getElementById("transition-cover")
  const directionTo = reverse ? "-100%" : "100%"
  const directionFrom = reverse ? "100%" : "-100%"

  const pausePercent = 0.5
  const swipePercent = (1 - pausePercent) / 2

  return (
    new TimelineMax()
      .set(transitionCover, {
        x: directionFrom,
        backgroundColor: color,
      })
      .to(transitionCover, length * swipePercent, {
        x: "0%",
        ease: Power1.easeInOut,
      })
      // Hold steady for described percent of animation length
      .to(transitionCover, length * pausePercent, {})

      .set(node, { opacity: 0 })
      .to(transitionCover, length * swipePercent, {
        x: directionTo,
        ease: Power1.easeIn,
      })
  )
}

const animationSeconds = 0.8

const SwipeLink = props => {
  const length = props.length ? parseFloat(props.length) : animationSeconds

  // If reverse is true, plays right to left
  const { reverse, color = "white" } = props

  if (!isInternal(props.to) || props.to.includes("mailto:")) {
    return (
      <OutboundLink
        href={props.to}
        rel="noopener noreferrer"
        target="_blank"
        {...omit(props, "activeClassName", "reverse")}
      >
        {props.children}
      </OutboundLink>
    )
  }

  // Add trailing slash if missing
  const to = props.to[props.to.length - 1] === "/" ? props.to : `${props.to}/`

  return (
    <Link
      exit={{
        length,
        trigger: ({ exit, node }) => {
          animate(node)(exit, { reverse, color })
        },
      }}
      entry={{ delay: length / 2 }}
      {...omit(props, "reverse")}
      to={to}
    />
  )
}

export default SwipeLink
