import React, { useRef, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "@components/seo"
import Enter from "@components/animate-entry"
import EnterAsRows from "@components/animate-row-entry"
import ScrollToBottom from "@components/scroll-to-bottom"
import Image from "@components/image"
import "./index.scss"
import Accordion from "../../components/intern-faq-accordion/accordion"
import ScrollToTop from "@components/scroll-to-top"
import ReactImageVideoLightbox from "react-image-video-lightbox"

const Wayfinder = () => {
  const { contentfulPage } = useStaticQuery(graphql`
    query Wayfinder {
      contentfulPage(uid: { eq: "wayfinder" }) {
        pageName
        pageDescription
        sections {
          ... on ContentfulPageSection {
            uid
            title
            subtitle
            imageSet {
              title
              file {
                url
              }
              description
            }
            image {
              title
              file {
                url
              }
            }
            description {
              description
            }
            cta {
              linkText
              destination
            }
            video {
              title
              video {
                id
                file {
                  url
                  fileName
                  contentType
                }
              }
              posterImage {
                id
                file {
                  url
                  fileName
                  contentType
                }
              }
            }
            content {
              ... on ContentfulPageSection {
                id
                title
                subtitle
                description {
                  description
                }
                image {
                  id
                  file {
                    url
                    fileName
                    contentType
                  }
                }
                imageSet {
                  title
                  file {
                    url
                  }
                  description
                }
              }
              ... on ContentfulInternStories {
                id
                name
                internProfileImage {
                  file {
                    url
                  }
                }
                roleAtAcubed
                project
                internStories {
                  internStories
                }
              }
              ... on ContentfulFaq {
                id
                question
                answer {
                  answer
                }
              }
            }
          }

          ... on ContentfulCta {
            id
            linkText
            destination
          }
        }
      }
    }
  `)

  const { pageName, pageDescription, sections } = contentfulPage

  const headerSection = sections.find(sect => sect.uid === "wayfinder-header")
  const videoSection = sections.find(sect => sect.uid === "wayfinder-video")
  const wayfinderAboutSection = sections.find(
    sect => sect.uid === "about-wayfinder"
  )
  const wayfinderGoalSection = sections.find(
    sect => sect.uid === "wayfinder-goal"
  )
  const wayfinderServiceSection = sections.find(
    sect => sect.uid === "wayfinder-services"
  )
  const wayfinderMissionSection = sections.find(
    sect => sect.uid === "wayfinder-mission"
  )
  const wayfinderFAQSection = sections.find(
    sect => sect.uid === "wayfinder-faq"
  )
  const detailSection = useRef(null)
  const [videoLightbox, setVideoLightbox] = useState(false)

  return (
    <section className="wayfinder">
      <SEO title={pageName} description={pageDescription} />
      <ScrollToTop />
      <article
        className="wayfinder-header"
        style={{
          backgroundImage: `url(${headerSection.image.file.url})`,
        }}
      >
        <div className="u-layout-max-width">
          <div className="content-section">
            <h1>{headerSection.title}</h1>
            <div className="u-display-width">
              <p>{headerSection.description.description}</p>
              <ScrollToBottom wayfinderDetail={detailSection} />
            </div>
          </div>
        </div>
      </article>

      <article className="wayfinder-video">
        <div className="content-section">
          <Image className="wayfinder-image" {...videoSection.image} lazy />
          <div
            className="wayfinder-center"
            onClick={() => setVideoLightbox(true)}
          >
            <Image
              className="wayfinder-icon"
              {...videoSection.imageSet[0]}
              lazy
            />
          </div>
          {videoLightbox && (
            <div className="lightbox-video">
              <ReactImageVideoLightbox
                data={[
                  {
                    url: videoSection.video.video.file.url,
                    type: "video",
                    title: videoSection.video.title,
                  },
                ]}
                startIndex={0}
                showResourceCount={false}
                onCloseCallback={() => setVideoLightbox(false)}
              />
            </div>
          )}
        </div>
      </article>

      <article
        className="wayfinder-mission wayfinder-details"
        ref={detailSection}
      >
        <div className="container-fluid">
          <Enter>
            <div className="content-section">
              <div className="wayfinder-intern">
                <div className="left-side">
                  <div
                    className="content-side"
                    style={{
                      backgroundImage: `url(${wayfinderAboutSection.image.file.url})`,
                    }}
                  >
                    {wayfinderAboutSection.content.map((about, i) =>
                      i < 1 ? (
                        <article key={about.id}>
                          <h3>{about.title}</h3>
                          <p className="goalsubtitle">{about.subtitle}</p>
                          <p className="description">
                            {about.description.description}
                          </p>
                        </article>
                      ) : null
                    )}
                  </div>
                </div>
                <div className="right-side">
                  {wayfinderAboutSection.content.map((about, i) =>
                    i > 0 ? (
                      <article key={about.id}>
                        <h3>
                          {about.title}
                          <span>{about.subtitle}</span>
                        </h3>

                        <p>{about.description.description}</p>
                      </article>
                    ) : null
                  )}
                </div>
              </div>
            </div>
          </Enter>
        </div>
      </article>

      <article
        className="wayfinder-goal"
        style={{
          backgroundImage: `url(${wayfinderGoalSection.image.file.url})`,
        }}
      >
        <div className="u-layout-max-width">
          <div className="goal-right">
            <div className="bar-down"></div>
            <Enter>
              <div className="content-section">
                <h4>{wayfinderGoalSection.title}</h4>
                <p className="subtitle">{wayfinderGoalSection.subtitle}</p>
                <p>{wayfinderGoalSection.description.description}</p>
              </div>
            </Enter>
          </div>
        </div>
      </article>

      <article
        className="wayfinder-services"
        style={{
          backgroundImage: `url(${wayfinderServiceSection.image.file.url})`,
        }}
      >
        <div className="u-layout-max-width">
          <div className="content-section">
            <Enter>
              <div className="center-side">
                <p>{wayfinderServiceSection.description.description}</p>
              </div>
            </Enter>
            <EnterAsRows delay={300}>
              <div className="centerfull-side">
                {wayfinderServiceSection.imageSet.map(image => (
                  <article key={image.file.url} className="services-content">
                    <figure className="services-icon-wrap">
                      <Image className="services-icon" {...image} lazy />
                    </figure>
                    <div className="services-content-inner">
                      <div className="services-content-wrap">
                        <p className="services-desc">{image.description}</p>
                      </div>
                    </div>
                  </article>
                ))}
              </div>
            </EnterAsRows>
          </div>
        </div>
      </article>

      <article className="wayfinder-mission">
        <div className="container-fluid">
          <Enter>
            <div className="content-section">
              <div className="left-side">
                {wayfinderMissionSection.content.map((mission, i) =>
                  i < 1 ? (
                    <article key={mission.id}>
                      <h4>{mission.title}</h4>
                      <p>{mission.description.description}</p>
                    </article>
                  ) : null
                )}
              </div>
              <div className="right-side">
                <div className="content-side">
                  {wayfinderMissionSection.content.map((mission, i) =>
                    i > 0 ? (
                      <article
                        key={mission.id}
                        style={{
                          backgroundImage: `url(${mission.image.file.url})`,
                        }}
                      >
                        {mission.imageSet.map(image => (
                          <div key={image.file.url} className="mission-content">
                            <div className="mission-icon-wrap">
                              <Image className="mission-icon" {...image} lazy />
                            </div>
                            <div className="mission-content-inner">
                              <p className="mission-desc">
                                {image.description}
                              </p>
                            </div>
                          </div>
                        ))}
                      </article>
                    ) : null
                  )}
                </div>
              </div>
            </div>
          </Enter>
        </div>
      </article>

      <article className="wayfinder-faq">
        <div className="content-section u-layout-max-width">
          <Enter>
            <h2 className="faq-title">
              {wayfinderFAQSection.description.description}
            </h2>
          </Enter>

          <Enter>
            <section>
              <div className="faq-content">
                {wayfinderFAQSection.content.map((faq, i) => (
                  <article key={faq.id} className="faq-section">
                    <Accordion
                      title={faq.question}
                      content={faq.answer.answer}
                    />
                  </article>
                ))}
              </div>
            </section>
          </Enter>
        </div>
      </article>
    </section>
  )
}

export default Wayfinder