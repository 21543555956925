import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./index.scss"

const ImageCarousel = ({ imageSet }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 5,
    centerMode: false,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        }
      }
    ]
  }

  return (
    <div className="image-carousel-container">
      <Slider {...settings}>
        {imageSet.map((image, index) => (
          <div className="image-slide" key={index}>
            <img src={image.file.url} alt={image.title} />
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default ImageCarousel
