import React, { useState } from "react"
import Sensor from "react-visibility-sensor"

// Generates an element with a background image that only loads when it's in/near the viewport
const LazyBg = ({ componentType = "div", bg, ...rest }) => {
  const [seen, markSeen] = useState(false)

  // If only a URL is given, don't overwrite other background properties
  const bgKey =
    bg.slice(0, 4) === "url(" && bg[bg.length - 1] === ")"
      ? "backgroundImage"
      : "background"

  return (
    <Sensor partialVisibility offset={{ top: 100 }}>
      {({ isVisible }) => {
        if (isVisible && !seen) {
          markSeen(true)
        }
        const style = rest.style
          ? { ...rest.style, [bgKey]: bg }
          : { [bgKey]: bg }

        return isVisible || seen
          ? React.createElement(componentType, { ...rest, style })
          : React.createElement(componentType, rest)
      }}
    </Sensor>
  )
}

export default LazyBg
