import { generateTeaser } from "@utils/string"

const charLimit = 175

// Takes markdown and returns the first paragraph (optionally truncated to set length)
// with all formatting pulled out.
const PostTeaser = ({ markdown, truncateLength = charLimit }) => {
  return generateTeaser(markdown, truncateLength)
}

export default PostTeaser
