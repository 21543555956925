import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import get from "lodash.get"
import moment from "moment"

import NewsBlog from "@components/news-blog"
import MediaCard from "@components/media-card"
import SEO from "@components/seo"
import Enter from "@components/animate-entry"

const News = () => {
  const { contentfulPage, allContentfulCoverageItem } = useStaticQuery(graphql`
    query News {
      contentfulPage(uid: { eq: "news" }) {
        pageName
        pageDescription
        sections {
          ... on ContentfulPageSection {
            id
            image {
              file {
                url
              }
              title
            }
            description {
              description
            }
            title
            downloads {
              title
              file {
                url
              }
            }
          }
        }
      }
      allContentfulCoverageItem(sort: { fields: datePublished, order: DESC }) {
        nodes {
          title
          link
          publication
          datePublished
        }
      }
    }
  `)

  const { pageName, pageDescription, sections } = contentfulPage

  const posts = (allContentfulCoverageItem.nodes || [])
    .map(p => ({ ...p, url: get(p.file, "file.url", p.link) }))
    .filter(
      p =>
        p.url &&
        // Filter out future posts, except in preview mode
        (process.env.GATSBY_PREVIEW ? true : moment(p.datePublished) < moment())
    )

  return (
    <NewsBlog hero={sections[0]}>
      <SEO title={pageName} description={pageDescription} />
      <section className="blog-cards u-layout-max-width">
        <article className="blog-card">
          {posts.map((post, index) => (
            <Enter key={post.title} delay={(index % 2) * 300}>
              <MediaCard {...post} />
            </Enter>
          ))}
        </article>
      </section>
    </NewsBlog>
  )
}

export default News
