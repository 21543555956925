import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

import Link from "@components/swipe-link"
import SEO from "@components/seo"
import Image from "@components/image"
import Button from "@components/button"

import "./index.scss"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

// eslint-disable-next-line
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const Contact = () => {
  const { contentfulPage } = useStaticQuery(graphql`
    query Contact {
      contentfulPage(uid: { eq: "contact-us" }) {
        pageName
        pageDescription
        sections {
          ... on ContentfulPageSection {
            title
            subtitle
            image {
              title
              file {
                url
              }
            }
            content {
              ... on ContentfulLinkGroup {
                name
                links {
                  text
                  destination
                  description {
                    description
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [errors, setErrors] = useState([])
  const [emailError, setEmailError] = useState(null)
  const [status, setStatus] = useState("unsent")

  const { sections, pageName, pageDescription } = contentfulPage
  const hero = sections[0]

  const fields = { name, email, message }
  const missing = ["name", "email", "message"].filter(k => !fields[k].length)

  const onSubmit = event => {
    event.preventDefault()

    if (missing.length) {
      setErrors(missing)
      return
    }

    if (!emailRegex.test(email)) {
      setErrors(["email"])
      return
    }

    const params = Object.keys(fields).reduce(
      (obj, key) => ({
        ...obj,
        [key]: fields[key],
      }),
      {}
    )

    setStatus("sending")

    const form = event.target
    const data = {
      "form-name": form.getAttribute("name"),
      ...params,
    }

    const body = encode(data)

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body,
    })
      .then(() => {
        setStatus("sent")
        setName("")
        setEmail("")
        setMessage("")
        trackCustomEvent({
          category: "Contact Form",
          action: "Submission",
          label: "Contact Form Submissions",
        })
      })
      .catch(err => {
        setEmailError(err.message)
        setStatus("failed")
      })
  }

  return (
    <section className="contact">
      <SEO title={pageName} description={pageDescription} />

      {/* hero */}
      <article className="contact-hero">
        <div className="contact-hero__content u-layout-max-width">
          <div className="contact-hero__content-inner">
            <h1 className="contact-hero__title">{hero.title}</h1>
            <p className="contact-hero__subtitle body-large">{hero.subtitle}</p>

            <div className="contact-hero__info">
              {hero.content.map(linkGroup => (
                <div className="contact-hero__row" key={linkGroup.name}>
                  <h4 className="contact-hero__link-group">{linkGroup.name}</h4>
                  <div className="contact-hero__link-group-wrap">
                    {linkGroup.links.map(link => (
                      <div
                        className="contact-hero__link-column"
                        key={link.text}
                      >
                        <p>{link.description.description}</p>
                        {link.destination.includes("maps") ? (
                          <Button to={link.destination} type="tertiary" icon>
                            {link.text}
                          </Button>
                        ) : (
                          <Link
                            className="button button-plain"
                            to={link.destination}
                          >
                            {link.text}
                          </Link>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="contact-hero__map">
          <Image className="contact-hero__map-image" {...hero.image} w="700" />
        </div>
      </article>

      {/* form */}
      <div className="contact-form u-layout-max-width">
        {status === "failed" ? (
          <p className="has-error">Message failed to send: {emailError}.</p>
        ) : null}
        <form
          className="contact-form__form"
          name="contact"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={onSubmit}
        >
          <input type="hidden" name="form-name" value="contact" />
          <h4 className="contact-form__header">Send us a message</h4>
          <p className="label">* Required fields</p>
          <article className="contact-form__row">
            <input
              className={errors.includes("name") ? "has-error" : ""}
              type="text"
              value={name}
              name="name"
              onChange={({ target }) => {
                setName(target.value)
                setErrors([])
                setEmailError(null)
                setStatus("unsent")
              }}
              placeholder="Name *"
            />
            <input
              className={errors.includes("email") ? "has-error" : ""}
              type="email"
              name="email"
              value={email}
              onChange={({ target }) => {
                setEmail(target.value)
                setErrors([])
                setEmailError(null)
                setStatus("unsent")
              }}
              placeholder="Email *"
            />
          </article>

          <article className="contact-form__row">
            <textarea
              className={errors.includes("message") ? "has-error" : ""}
              type="text"
              name="message"
              value={message}
              onChange={({ target }) => {
                setMessage(target.value)
                setErrors([])
                setEmailError(null)
                setStatus("unsent")
              }}
              placeholder="Message *"
            />
          </article>

          <input
            className={`button button-primary u-mb0 ${
              missing.length ? "disabled" : ""
            }`}
            type="submit"
            value={status === "sending" ? "Sending..." : "Send"}
          />
          {status === "sent" ? <p className="contact-us-response">Message sent!</p> : null}
        </form>
      </div>
    </section>
  )
}

export default Contact
