// NOTE: This file is in non-ES6 Javascript, because it is required server-side

const kebabCase = require("lodash.kebabcase")

function makeSlug(...substrings) {
  return substrings
    .filter(s => s)
    .map(kebabCase)
    .join("-")
}

function makeJobSlug(job, projectName) {
  const title = job.title
  return makeSlug(projectName, title)
}

function makeProjectSlug(project) {
  return kebabCase(project.name)
}

function makeEmployeeSlug(employee) {
  return kebabCase(employee.name)
}

function makeBlogPostSlug(post) {
  return kebabCase(post.title)
}

// Exports

const makePath = {
  job: (job, projectName) =>
    `/careers/${projectName ? makeSlug(projectName) : "acubed"}/${makeJobSlug(
      job
    )}/`,
  blogPost: post =>
    `/blog/${
      post.project ? makeProjectSlug(post.project) : "acubed"
    }/${makeBlogPostSlug(post)}/`,
  leader: emp => `/about-us/leadership/${makeEmployeeSlug(emp)}/`,
  project: project => `/projects/${makeProjectSlug(project)}/`,
  projectBlog: project => `/blog/${makeProjectSlug(project)}/`,
  projectCareers: project => `/careers/${makeProjectSlug(project)}/`,
}

function isInternal(url) {
  return /^\/(?!\/)/.test(url)
}

module.exports.isInternal = isInternal

module.exports.makePath = makePath
