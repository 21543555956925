import React from "react"
import { Provider } from "react-redux"

import store from "@store"
import Layout from "../layout"

import "./index.scss"

const App = ({ children, location }) => (
  <Provider store={store}>
    <Layout location={location}>{children}</Layout>
  </Provider>
)

export default App
