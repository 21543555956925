export default function animateLink() {
  var navigation = document.querySelector('.filter'),
    navigationItems = navigation.querySelectorAll('li'),
    navigationLine = navigation.querySelector('.line'),
    navigationItemActive = navigation.querySelector('li.filter__item--selected');

  function getComputedTranslateX(obj) {
    if (!window.getComputedStyle) return;
    var style = getComputedStyle(obj),
      transform = style.transform || style.webkitTransform || style.mozTransform;
    var mat = transform.match(/^matrix3d\((.+)\)$/);
    if (mat) return parseFloat(mat[1].split(', ')[12]);
    mat = transform.match(/^matrix\((.+)\)$/);
    return mat ? parseFloat(mat[1].split(', ')[4]) : 0;
  }

  var moveLineToElement = function (el) {
    var distance = el.offsetLeft - getComputedTranslateX(navigationLine);
    navigationLine.style.transitionDuration = (400 + (Math.abs(distance) * 2)) + 'ms';
    navigationLine.style.transform = 'matrix(1,0,0,1,' + el.offsetLeft + ',' + (27) + ')';
    navigationLine.style.width = el.offsetWidth + 'px';
  };

  var oldTransition = navigationLine.style.transition;
  // navigationLine.style.transition = 'none';
  moveLineToElement(navigationItemActive);
  setTimeout(function () {
    navigationLine.style.transition = oldTransition;
  }, 100);

  var navMouseOutTimeout = null;

  var navMouseOver = function (e) {
    if (navMouseOutTimeout != null) {
      clearTimeout(navMouseOutTimeout);
    }
    moveLineToElement(e.target);
  };
  var navMouseOut = function (e) {
    if (navMouseOutTimeout != null) {
      clearTimeout(navMouseOutTimeout);
    }
    navMouseOutTimeout = setTimeout(function () {
      moveLineToElement(navigationItemActive);
    }, 200);
  };

  for (var i = 0; i < navigationItems.length; i++) {
    navigationItems[i].addEventListener('mouseover', navMouseOver);
    navigationItems[i].addEventListener('mouseout', navMouseOut);
  }
}