import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import SEO from "@components/seo"
import Image from "@components/image"
import Enter from "@components/animate-entry"
import Markdown from "@components/markdown"

const DroneDeploy = () => {
  const { contentfulPage } = useStaticQuery(graphql`
    query DroneDeploy {
      contentfulPage(uid: { eq: "dronedeploy" }) {
        pageDescription
        pageName
        sections {
          ... on ContentfulPageSection {
            uid
            id
            title
            description {
              description
            }
            image {
              title
              file {
                url
              }
            }
          }
        }
      }
    }
  `)

  const { sections, pageName, pageDescription } = contentfulPage
  const DroneSection = sections.find(
    sect => sect.uid === `DroneDeploy to offer airspace authorization and flight briefings for commercial drone pilots`
  )

  return (
    <section className="DroneDeploy">
      <SEO title={pageName} description={pageDescription} />

      <div className="u-layout-max-width hero-section">
        <h2>{pageName}</h2>
      </div>

      <article className="DroneSection-article">
        <div className="u-layout-max-width">
          <Enter>
            <h2 className="title">{DroneSection.title}</h2>
          </Enter>
        </div>
        <div className="DroneSection-image">
          <Enter>
            <div className="project-hero__image-wrap">
              <Image
                className="project-hero__image"
                {...DroneSection.image}
                lazyDims={{ width: "100vw" }}
              />
            </div>
          </Enter>
        </div>

        <div>
          <Enter>
            <div className="DroneSection-description u-layout-max-width">
              {DroneSection && DroneSection.description && (
                <Markdown
                  source={DroneSection.description.description}
                />
              )}
            </div>
          </Enter>
        </div>
      </article>     
    </section>
  )
}

export default DroneDeploy
