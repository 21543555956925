import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Link from "@components/swipe-link"
import SEO from "@components/seo"
import Image from "@components/image"
import Button from "@components/button"
import Enter from "@components/animate-entry"
import HeroSection from "@components/hero-section"
import Markdown from "@components/markdown"

const Prototype = () => {
  const { contentfulPage } = useStaticQuery(graphql`
    query Prototype {
      contentfulPage(uid: { eq: "prototype" }) {
        pageDescription
        pageName
        sections {
          ... on ContentfulPageSection {
            uid
            id
            title
            description {
              description
            }
            image {
              title
              file {
                url
              }
            }
          }
        }
      }
    }
  `)

  const { sections, pageName, pageDescription } = contentfulPage
  const UTMPrototypeSection = sections.find(
    sect => sect.uid === `Meet the Airbus UTM "Prototype"`
  )
  const airbusSimulationSection = sections.find(
    sect =>
      sect.uid ===
      "Learn about the Airbus Simulation Tool Shaping the UTM Systems of the Future"
  )
  const UTMToolsetSection = sections.find(
    sect =>
      sect.uid ===
      "Have you considered and embraced the idea of collaborative simulation as part of your UTM toolset? "
  )
  const technicalPaperSection = sections.find(
    sect => sect.uid === "Technical Papers"
  )

  return (
    <section className="Prototype">
      <SEO title={pageName} description={pageDescription} />

      <div className="u-layout-max-width hero-section">
        <h2>{pageName}</h2>
      </div>

      <article className="UTMPrototypeSection-article">
        <div className="u-layout-max-width">
          <Enter>
            <h2 className="title">{UTMPrototypeSection.title}</h2>
          </Enter>
        </div>
        <div className="UTMPrototypeSection-image">
          <Enter>
            <div className="project-hero__image-wrap">
              <Image
                className="project-hero__image"
                {...UTMPrototypeSection.image}
                lazyDims={{ width: "100vw" }}
              />
            </div>
          </Enter>
        </div>

        <div>
          <Enter>
            <div className="UTMPrototypeSection-description">
              {UTMPrototypeSection && UTMPrototypeSection.description && (
                <Markdown
                  source={UTMPrototypeSection.description.description}
                />
              )}
            </div>
          </Enter>
        </div>
      </article>

      <article className="airbusSimulationSection-article">
        <div className="u-layout-max-width">
          <Enter>
            <h2 className="title">{airbusSimulationSection.title}</h2>
          </Enter>

          <Enter>
            <div className="airbusSimulationSection-description">
              {airbusSimulationSection &&
                airbusSimulationSection.description && (
                  <Markdown
                    source={airbusSimulationSection.description.description}
                  />
                )}
            </div>
          </Enter>
        </div>
      </article>

      <article className="UTMToolsetSection-article">
        <div className="u-layout-max-width">
          <Enter>
            <h2 className="title">{UTMToolsetSection.title}</h2>
          </Enter>

          <Enter>
            <div className="UTMToolsetSection-description">
              {UTMToolsetSection && UTMToolsetSection.description && (
                <Markdown source={UTMToolsetSection.description.description} />
              )}
            </div>
          </Enter>
        </div>
      </article>

      <article className="technicalPaperSection-article">
        <div className="u-layout-max-width">
          <Enter>
            <h2 className="title">{technicalPaperSection.title}</h2>
          </Enter>

          <Enter>
            <div className="technicalPaperSection-description">
              {technicalPaperSection && technicalPaperSection.description && (
                <Markdown
                  source={technicalPaperSection.description.description}
                />
              )}
            </div>
          </Enter>
        </div>
      </article>
    </section>
  )
}

export default Prototype
