import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Link from "@components/swipe-link"
import SEO from "@components/seo"
import Image from "@components/image"
import Button from "@components/button"
import Enter from "@components/animate-entry"
import HeroSection from "@components/hero-section"
import Markdown from "@components/markdown"

import "./index.scss"

const UTMPublications = () => {
  const { contentfulPage } = useStaticQuery(graphql`
    query UTMPublications {
      contentfulPage(uid: { eq: "utm-publications" }) {
        pageDescription
        pageName
        sections {
          ... on ContentfulPageSection {
            uid
            id
            title
            description {
              description
            }
            image {
              title
              file {
                url
              }
            }
            cta {
              linkText
              destination
              description {
                description
              }
            }
          }
        }
      }
    }
  `)

  const { sections, pageName, pageDescription } = contentfulPage
  const content = sections[0]
  const utmBlueprintSection = sections.find(
    sect => sect.uid === "utm-blueprint"
  )
  const perceptionStudySection = sections.find(
    sect => sect.uid === "community-perception-study"
  )
  const technicalWhitepapersSection = sections.find(
    sect => sect.uid === "technical-whitepapers"
  )
  const prototypeSection = sections.find(sect => sect.uid === "prototype")
  const airspaceFairnessSection = sections.find(
    sect => sect.uid === "airspace-fairness"
  )
  const glossarySection = sections.find(sect => sect.uid === "glossary")
  const memosSection = sections.find(sect => sect.uid === "memos")
  const newDigitalEraSection = sections.find(
    sect => sect.uid === "new-digital-era"
  )

  const sectionsToRender = [
    utmBlueprintSection,
    perceptionStudySection,
    technicalWhitepapersSection,
    prototypeSection,
    airspaceFairnessSection,
    glossarySection,
    memosSection,
    newDigitalEraSection,
  ]

  const renderedSections = sectionsToRender.map(section =>
    renderSection(section)
  )

  function renderSection(section) {
    return (
      <article
        className="UTMPublications-article publication-list"
        key={section.uid}
      >
        <div className={`container u-layout-max-width utm-lists`}>
          <div className="left-section">
            <h3>{section.title}</h3>
            {section.description && section.description.description && (
              <Markdown source={section.description.description} />
            )}
          </div>
          <div className="right-section">
            {section.cta && section.cta.destination && section.cta.linkText && (
              <a
                href={section.cta.destination}
                className="button button-secondary same-size-button"
                target="_blank"
              >
                {section.cta.linkText}
              </a>
            )}
          </div>
        </div>
      </article>
    )
  }

  return (
    <section className="UTMPublications">
      <SEO title={pageName} description={pageDescription} />

      <article className="UTMPublications-article hero-section">
        <div className="u-layout-max-width">
          <Enter>
            <h2 className="title">{content.title}</h2>
          </Enter>
        </div>
        <div className="UTMPublications-image">
          <Enter>
            <div className="project-hero__image-wrap">
              <Image
                className="project-hero__image"
                {...content.image}
                lazyDims={{ width: "100vw" }}
              />
            </div>
          </Enter>
        </div>

        <div className="u-layout-max-width">
          <Enter>
            <div className="UTMPublications-description">
              <Markdown source={content.description.description} />
            </div>
          </Enter>
        </div>
      </article>

      {renderedSections}
    </section>
  )
}

export default UTMPublications
