import React from "react"

import { formatDate } from "@utils/string"
import PostTeaser from "@components/post-teaser"
import Link from "@components/swipe-link"
import LazyBg from "@components/lazy-bg"
import { makePath } from "@utils/url"
import { imageUrl } from "@utils/image"

import "./index.scss"

const PostCard = ({ post, className }) => {
  const { title, body, project, datePublished, heroImage } = post
  return (
    <Link className={`post-card ${className}`} to={makePath.blogPost(post)}>
      <div className="post-card__image-wrap">
        <LazyBg
          className="post-card__image"
          bg={`#eee url(${imageUrl(heroImage.file.url, {
            q: 80,
            w: 700,
            h: 700,
          })}) center center/cover no-repeat`}
        />
      </div>

      <div className="post-card__content">
        <div className="post-card__metadata">
          <span>{formatDate(datePublished)}</span>
          {project && <span className="post-card__dot">&nbsp; • &nbsp;</span>}
          {project && <span>{project.name}</span>}
        </div>
        <h3 className="post-card__title">{title}</h3>
        <p className="post-card__copy">
          <PostTeaser markdown={body.body} />
        </p>
      </div>
    </Link>
  )
}

export default PostCard
