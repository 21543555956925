module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[],"layout":"/opt/build/repo/src/containers/app/index.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"acubed-by-airbus","short_name":"starter","start_url":"/","background_color":"#fff","theme_color":"#43c6dc","display":"minimal-ui","icon":"src/images/a3-logo-square.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6eb97fe5079500fad26cb826eabb3863"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
