import React from "react"

import Link from "@components/swipe-link"
import cn from "@utils/cn"
import { isInternal } from "@utils/url"
import IconArrowLeft from "@images/icon-arrow-left.svg"
import IconArrowRight from "@images/icon-arrow-right.svg"
import IconArrowRightDiagonal from "@images/icon-arrow-right-diagonal.svg"

const Button = ({
  type = "primary",
  to,
  children,
  icon = false,
  back = false,
  className,
  style = {},
}) => {
  const direction = back ? "left" : "right"

  const Icon = isInternal(to)
    ? back
      ? IconArrowLeft
      : IconArrowRight
    : IconArrowRightDiagonal

  const classes = [
    "button",
    `button-${type}`,
    `button-${type}-${direction}`,
    className,
  ]

  const contents = icon ? (
    <span style={style} className={`button-${type}__content-wrap`}>
      <Icon
        className={cn(
          `button-${type}__icon`,
          direction === "right" && `button-${type}__icon--right`
        )}
      />
      <span className={`button-${type}__text`}>{children}</span>
    </span>
  ) : (
    children
  )
  return (
    <Link style={style} className={cn(...classes)} to={to}>
      {contents}
    </Link>
  )
}

export default Button
