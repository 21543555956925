import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Link from "@components/swipe-link"
import SEO from "@components/seo"
import Image from "@components/image"
import Button from "@components/button"
import Enter from "@components/animate-entry"
import HeroSection from "@components/hero-section"
import Markdown from "@components/markdown"

const UAMResources = () => {
  const { contentfulPage } = useStaticQuery(graphql`
    query UAMResources {
      contentfulPage(uid: { eq: "uam-resources-technical-reports" }) {
        pageDescription
        pageName
        sections {
          ... on ContentfulPageSection {
            uid
            id
            title
            description {
              description
            }
            image {
              title
              file {
                url
              }
            }
          }
        }
      }
    }
  `)

  const { sections, pageName, pageDescription } = contentfulPage
  const ExecutiveSummarySection = sections.find(
    sect =>
      sect.uid === "Executive Summary: Preliminary Community Perception Study"
  )
  const TR009Section = sections.find(
    sect =>
      sect.uid ===
      "TR-009: Effectiveness of Preflight Deconfliction in High-Density UAS Operations"
  )
  const TR008Section = sections.find(
    sect =>
      sect.uid ===
      "TR-008: A Quantitative Framework for UAV Risk Assessment: Version 1.0"
  )
  const TR007Section = sections.find(
    sect => sect.uid === "TR-007: Managing UAS Noise"
  )
  const TR006Section = sections.find(
    sect =>
      sect.uid ===
      "TR-006: Applying Visual Separation Principles to UAV Flocking"
  )
  const TR004Section = sections.find(
    sect =>
      sect.uid === "TR-004: Metrics to Characterize Dense Airspace Traffic"
  )
  const TR003Section = sections.find(
    sect => sect.uid === "TR-003: Using Fault Trees to Compute UAV Mission Risk"
  )
  const TR002Section = sections.find(
    sect =>
      sect.uid ===
      "TR-002: Metrics for Near-Miss EventsUnderstanding Airprox, NMAC and “Inadequate Separation”"
  )
  const TR001Section = sections.find(
    sect => sect.uid === "TR-001: Understanding UAV Mission Risk"
  )

  return (
    <section className="UAMResources">
      <SEO title={pageName} description={pageDescription} />

      <div className="u-layout-max-width hero-section">
        <h2>{pageName}</h2>
      </div>

      <article className="ExecutiveSummarySection-article">
        <div className="u-layout-max-width">
          <Enter>
            <h2 className="title">{ExecutiveSummarySection.title}</h2>
          </Enter>

          <Enter>
            <div className="ExecutiveSummarySection-description">
              {ExecutiveSummarySection &&
                ExecutiveSummarySection.description && (
                  <Markdown
                    source={ExecutiveSummarySection.description.description}
                  />
                )}
            </div>
          </Enter>
        </div>
      </article>

      <article className="TR009Section-article">
        <div className="u-layout-max-width">
          <Enter>
            <h2 className="title">{TR009Section.title}</h2>
          </Enter>

          <Enter>
            <div className="TR009Section-description">
              {TR009Section && TR009Section.description && (
                <Markdown source={TR009Section.description.description} />
              )}
            </div>
          </Enter>
        </div>
      </article>

      <article className="TR008Section-article">
        <div className="u-layout-max-width">
          <Enter>
            <h2 className="title">{TR008Section.title}</h2>
          </Enter>

          <Enter>
            <div className="TR008Section-description">
              {TR008Section && TR008Section.description && (
                <Markdown source={TR008Section.description.description} />
              )}
            </div>
          </Enter>
        </div>
      </article>

      <article className="TR007Section-article">
        <div className="u-layout-max-width">
          <Enter>
            <h2 className="title">{TR007Section.title}</h2>
          </Enter>

          <Enter>
            <div className="TR007Section-description">
              {TR007Section && TR007Section.description && (
                <Markdown source={TR007Section.description.description} />
              )}
            </div>
          </Enter>
        </div>
      </article>

      <article className="TR006Section-article">
        <div className="u-layout-max-width">
          <Enter>
            <h2 className="title">{TR006Section.title}</h2>
          </Enter>

          <Enter>
            <div className="TR006Section-description">
              {TR006Section && TR006Section.description && (
                <Markdown source={TR006Section.description.description} />
              )}
            </div>
          </Enter>
        </div>
      </article>

      <article className="TR004Section-article">
        <div className="u-layout-max-width">
          <Enter>
            <h2 className="title">{TR004Section.title}</h2>
          </Enter>

          <Enter>
            <div className="TR004Section-description">
              {TR004Section && TR004Section.description && (
                <Markdown source={TR004Section.description.description} />
              )}
            </div>
          </Enter>
        </div>
      </article>

      <article className="TR003Section-article">
        <div className="u-layout-max-width">
          <Enter>
            <h2 className="title">{TR003Section.title}</h2>
          </Enter>

          <Enter>
            <div className="TR003Section-description">
              {TR003Section && TR003Section.description && (
                <Markdown source={TR003Section.description.description} />
              )}
            </div>
          </Enter>
        </div>
      </article>

      <article className="TR002Section-article">
        <div className="u-layout-max-width">
          <Enter>
            <h2 className="title">{TR002Section.title}</h2>
          </Enter>

          <Enter>
            <div className="TR002Section-description">
              {TR002Section && TR002Section.description && (
                <Markdown source={TR002Section.description.description} />
              )}
            </div>
          </Enter>
        </div>
      </article>

      <article className="TR001Section-article">
        <div className="u-layout-max-width">
          <Enter>
            <h2 className="title">{TR001Section.title}</h2>
          </Enter>

          <Enter>
            <div className="TR001Section-description">
              {TR001Section && TR001Section.description && (
                <Markdown source={TR001Section.description.description} />
              )}
            </div>
          </Enter>
        </div>
      </article>
    </section>
  )
}

export default UAMResources
