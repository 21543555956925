import React, { useEffect } from "react";

import cn from "@utils/cn"

import "./index.scss"
import animateLink from "./filter-animate";

const Filter = ({
  items,
  selectedIndex,
  onItemClick,
}) => {
  
  useEffect(() => {
    animateLink();
  })

  return (
      <article className="filter">
        <div className="line"></div>
        <ul className="filter__list">
          {items.map((item, index) => (
            <li
              className={cn(
                "filter__item",
                selectedIndex === index && "filter__item--selected"
              )}
              key={item}
              onClick={() => onItemClick(item, index)}
            >
              <span>{item}</span>
            </li>
          ))}
        </ul>
      </article>
  );
}

export default Filter;