import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import moment from "moment"

import { removeEnd } from "@utils/string"
import { makePath } from "@utils/url"
import NewsBlog from "@components/news-blog"
import PostCard from "@components/post-card"
import Dropdown from "@components/dropdown"
import SEO from "@components/seo"
import Stagger from "@components/animate-row-entry"

const Blog = ({ selectedProject }) => {
  const { contentfulPage, allContentfulBlogPost } = useStaticQuery(graphql`
    query Blog {
      contentfulPage(uid: { eq: "blog" }) {
        pageName
        pageDescription
        sections {
          ... on ContentfulPageSection {
            id
            image {
              file {
                url
              }
              title
            }
            description {
              description
            }
            title
          }
        }
      }

      allContentfulBlogPost(sort: { fields: datePublished, order: DESC }) {
        nodes {
          title
          datePublished
          createdAt
          body {
            body
          }
          heroImage {
            file {
              url
            }
            title
          }
          project {
            name
          }
        }
      }
    }
  `)

  // Filter out future posts except in preview mode
  const posts = process.env.GATSBY_PREVIEW
    ? allContentfulBlogPost.nodes
    : allContentfulBlogPost.nodes.filter(
        p => moment(p.datePublished) < moment()
      )

  const { pageName, pageDescription, sections } = contentfulPage
  const projectDropdownItems = posts.reduce(
    (items, post) =>
      !post.project || items.includes(post.project.name)
        ? items
        : [...items, post.project.name],
    []
  )
  const dropdownItems = ["All Topics", ...projectDropdownItems, "Acubed"]

  const [dropdownIndex, setDropdownIndex] = useState(
    Math.max(dropdownItems.indexOf(selectedProject), 0)
  )
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const metaDescription =
    selectedProject && pageDescription.includes("from Acubed by Airbus.")
      ? `${removeEnd(pageDescription, ".")} and ${selectedProject}.`
      : pageDescription

  const metaTitle =
    selectedProject && pageName.includes(" - Blog")
      ? pageName.replace(" - Blog", ` - ${selectedProject} Blog`)
      : pageName

  return (
    <NewsBlog hero={sections[0]}>
      <SEO title={metaTitle} description={metaDescription} />
      <Dropdown
        className="news-blog__dropdown"
        items={dropdownItems}
        selectedIndex={dropdownIndex}
        onTitleClick={() => setDropdownOpen(!dropdownOpen)}
        onItemClick={(item, index) => {
          setDropdownIndex(index)
          setDropdownOpen(false)

          // Update URL as well
          if (projectDropdownItems.includes(item)) {
            window.history.replaceState(
              "",
              "",
              makePath.projectBlog({ name: item })
            )
          }
        }}
        isOpen={dropdownOpen}
      />
      <section className="blog-cards u-layout-max-width">
        <Stagger delay={150}>
          <article className="blog-card">
            {posts
              .filter(
                post =>
                  dropdownIndex === 0 ||
                  (dropdownIndex === dropdownItems.length - 1 &&
                    !post.project) ||
                  (post.project &&
                    post.project.name === dropdownItems[dropdownIndex])
              )
              .map((post, index) => (
                <PostCard key={post.title} post={post} />
              ))}
          </article>
        </Stagger>
      </section>
    </NewsBlog>
  )
}

export default Blog
