import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

import Link from "@components/swipe-link"
import Logo from "@images/a3-logo.svg"
import IconArrowRightDiagonal from "@images/icon-arrow-right-diagonal.svg"
// Mail chimp component
import MailChimpfooter from "@components/mailchimpfooter"

import "./index.scss"

const Footer = () => {
  const { contentfulNavSection } = useStaticQuery(graphql`
    query Footer {
      contentfulNavSection(uid: { eq: "footer" }) {
        description {
          description
        }
        linkBuckets {
          name
          links {
            text
            destination
          }
        }
      }
    }
  `)

  const { linkBuckets, description } = contentfulNavSection
  const externalLinks = linkBuckets.find(bucket => bucket.name === "External")
  const otherLinkGroups = linkBuckets.filter(
    bucket => bucket.name !== "External"
  )

  return (
    <footer className="footer">
      {/* upper */}
      <article className="footer__upper u-layout-max-width">
        {/* section */}
        <div className="footer__brand-group">
          <Logo className="footer__logo" />
          <p className="footer__desc">{description.description}</p>
        </div>

        {/* section */}
        <div className="footer__link-group">
          {otherLinkGroups.map(linkGroup => (
            <div key={linkGroup.name} className="footer__links">
              <h4 className="footer__link-header">{linkGroup.name}</h4>
              <div className="footer__links-list">
                {linkGroup.links.map(link => (
                  <Link
                    key={link.text}
                    to={link.destination}
                    activeClassName="active"
                    onClick={() => {
                      trackCustomEvent({
                        category: "Footer Link",
                        action: "Click",
                        label: `${link.text} - ${link.destination}`,
                      })
                    }}
                  >
                    {link.text}
                  </Link>
                ))}
              </div>
            </div>
          ))}
        </div>

        {/* section */}
        <div className="footer__external-group">
          <div className="footer__external-linkwrap">
            {externalLinks.links.map(link => (
              <Link
                key={link.text}
                to={link.destination}
                className="footer__external-link"
                activeClassName="active"
                onClick={() => {
                  trackCustomEvent({
                    category: "Footer Link",
                    action: "Click",
                    label: `${link.text} - ${link.destination}`,
                  })
                }}
              >
                <h4 className="footer__link-header">{link.text}</h4>
                <IconArrowRightDiagonal className="footer__external-link-icon" />
              </Link>
            ))}
          </div>
          {/* <div className="footer__subscribe">
            <h4>Subscribe to our newsletter</h4>
            <p>Keep up with our latest news and events</p>
            <MailChimpfooter className='subscribe_form' />
          </div> */}
        </div>
      </article>

      <p class="ethical u-layout-max-width">Ethical and legal considerations surrounding AI have never been more critical. We strive to implement both at the core of our AI activities.</p>

      {/* lower */}
      <article className="footer__lower u-layout-max-width">
        <p className="footer__fine-print">
          &copy; {new Date().getFullYear()} by Airbus. All rights reserved.
        </p>
        <div className="footer__links-right-side">
          <Link
            className="footer__fine-print"
            to="/terms-and-privacy"
            onClick={() => {
              trackCustomEvent({
                category: "Footer Link",
                action: "Click",
                label: `Terms and Privacy - /terms-and-privacy`,
              })
            }}
          >
            Terms and Privacy
          </Link>
          <a className="footer__fine-print optanon-show-settings cookie-link" >Cookie Settings</a>
        </div>
      </article>
    </footer>
  )
}

export default Footer
