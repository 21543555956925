import React, { useState } from "react"
import get from "lodash.get"
import { useStaticQuery, graphql } from "gatsby"

import Link from "@components/swipe-link"
import SEO from "@components/seo"
import Dropdown from "@components/dropdown"
import Markdown from "@components/markdown"
import Enter from "@components/animate-entry"
import IconSearch from "@images/icon-search.svg"
import { makePath } from "@utils/url"
import { makeLocationString } from "@utils/string"

import "./index.scss"

const CareersIndex = ({ projectName }) => {
  const {
    allGreenhouse,
    contentfulPage,
    allContentfulProject,
  } = useStaticQuery(graphql`
    query Careers {
      allGreenhouse {
        nodes {
          jobs {
            title
            location {
              name
            }
            offices {
              location
              name
            }
            internal_job_id
            departments {
              name
              alternative_id
            }
            content
            alternative_id
            absolute_url
            updated_at
          }
        }
      }
      contentfulPage(uid: { eq: "careers" }) {
        pageName
        pageDescription
        sections {
          ... on ContentfulPageSection {
            uid
            __typename
            title
            description {
              description
            }
            cta {
							linkText
							destination
						}
          }
          ... on ContentfulCta {
            __typename
            titleText
            destination
            linkText
          }
        }
      }

      allContentfulProject {
        nodes {
          name
          greenhouseDepartmentId
        }
      }
    }
  `)
  const projects = allContentfulProject.nodes

  // Flat list of job objects
  const jobs = (allGreenhouse.nodes || []).reduce(
    (array, node) =>
      array.concat(
        (node.jobs || []).map(job => {
          const project = projects.find(
            p =>
              p.greenhouseDepartmentId ===
              get(job, "departments[0].alternative_id")
          )
          // Trim weird titles, eg "ADAM - GA20007205"
          const departmentName = get(job, "departments[0].name", "").split(
            " - "
          )[0]

          return {
            ...job,
            departmentName,
            projectName: get(project, "name"),
          }
        }) || []
      ),
    []
  )

  const nonProjectDepartments = jobs
    .filter(n => {
      const hasDepartmentName = get(n, "departments[0].name")
      const isNotProject = projects.every(
        p =>
          p.greenhouseDepartmentId !== get(n, "departments[0].alternative_id")
      )
      return hasDepartmentName && isNotProject
    })
    .map(n => get(n, "departments[0].name").split(" - ")[0])
    .reduce((list, el) => (list.includes(el) ? list : [...list, el]), [])

  const projectNames = jobs
    .map(job => job.projectName)
    .reduce(
      (list, name) => (list.includes(name) || !name ? list : [...list, name]),
      []
    )
  const dropdownItems = [
    "All Departments",
    ...projectNames,
    ...nonProjectDepartments,
    "Other",
  ]

  const [searchTerm, setSearchTerm] = useState("")
  const [dropdownIndex, setDropdownIndex] = useState(
    Math.max(dropdownItems.indexOf(projectName), 0)
  )
  const [openFilter, setOpenFilter] = useState(null)

  const projectGroups = {}
  const deptGroups = {}
  const other = []

  jobs.forEach(job => {
    if (dropdownItems.includes(job.projectName)) {
      projectGroups[job.projectName] = projectGroups[job.projectName]
        ? [...projectGroups[job.projectName], job]
        : [job]
    } else if (dropdownItems.includes(job.departmentName)) {
      deptGroups[job.departmentName] = deptGroups[job.departmentName]
        ? [...deptGroups[job.departmentName], job]
        : [job]
    } else {
      other.push(job)
    }
  })

  const matchesSearch = job =>
    !searchTerm.length ||
    job.title.toLowerCase().includes(searchTerm.toLowerCase())

  const groups = [
    ...Object.keys(projectGroups).map(name => ({
      name,
      jobs: projectGroups[name].filter(
        j =>
          (!dropdownIndex || dropdownItems[dropdownIndex] === j.projectName) &&
          matchesSearch(j)
      ),
    })),
    ...Object.keys(deptGroups).map(name => ({
      name,
      jobs: deptGroups[name].filter(
        j =>
          (!dropdownIndex ||
            dropdownItems[dropdownIndex] === j.departmentName) &&
          matchesSearch(j)
      ),
    })),
    {
      name: "Other",
      jobs: other.filter(
        j =>
          (!dropdownIndex || dropdownItems[dropdownIndex] === "Other") &&
          matchesSearch(j)
      ),
    },
  ]

  const { pageName, sections, pageDescription } = contentfulPage

  const header = sections.find(s => s.uid === "careers-header")
  const smallPrint = sections.find(s => s.uid === "careers-small-print")
  const cta = sections.find(s => s.__typename === "ContentfulCta")

  const noJobs = groups.every(group => group.jobs.length === 0)

  const metaDescription =
    projectName && pageDescription.includes("next adventure with")
      ? pageDescription.replace(
          "next adventure with",
          `next adventure working on ${projectName} with`
        )
      : pageDescription

  const metaTitle =
    projectName && pageName.includes("- Engineering jobs")
      ? pageName.replace(
          "- Engineering jobs",
          `- ${projectName} Engineering jobs`
        )
      : pageName

  return (
    <section className="careers">
      <SEO title={metaTitle} description={metaDescription} />
      <div className="careers__content u-layout-max-width">
        <Enter>
          <article className="careers__header">
            <h1>{header.title}</h1>
            <Enter delay={200}>
              <Markdown source={header.description.description} />
            </Enter>
            <div className="hero-section__cta">
              <a href={header.cta && header.cta.destination} className="button button-primary">{header.cta && header.cta.linkText}</a>
            </div>
          </article>
        </Enter>

        <Enter>
          <section className="careers__filters">
            <article className="careers__search">
              <input
                className="careers__search-input"
                type="text"
                value={searchTerm}
                onChange={({ target }) => setSearchTerm(target.value)}
                placeholder="Search"
              />
              <IconSearch width="17" height="17" />
            </article>
            <Dropdown
              items={dropdownItems}
              isOpen={openFilter === "projects"}
              selectedIndex={dropdownIndex}
              onItemClick={(item, index) => {
                setDropdownIndex(index)
                setOpenFilter(null)
              }}
              onTitleClick={isOpen => setOpenFilter(isOpen ? null : "projects")}
            />
          </section>
        </Enter>

        <div className="careers__projects">
          {searchTerm.length > 0 && !noJobs ? (
            <h3 className="careers__results-header">Search results:</h3>
          ) : null}

          {noJobs ? (
            <h3 className="careers__no-jobs">No results....</h3>
          ) : (
            groups
              .filter(g => g.jobs.length > 0)
              .map(group => (
                <div className="careers__group" key={group.name}>
                  <Enter>
                    <h4 className="u-upcase">{group.name}</h4>
                  </Enter>
                  <ul className="careers-list">
                    {group.jobs.map((job, ind) => {
                      const path = makePath.job(job, job.projectName)
                      const location = makeLocationString(
                        get(job, "location.name", "")
                      )

                      return (
                        <Enter key={path}>
                          <li className="careers-list__listing">
                            <div className="careers-list__title">
                              {job.title}
                            </div>
                            {location.length > 0 && (
                              <div className="careers-list__location">
                                {location}
                              </div>
                            )}
                            <Link
                              className="button button-secondary careers-list__cta"
                              to={path}
                            >
                              View Details
                            </Link>
                          </li>
                        </Enter>
                      )
                    })}
                  </ul>
                </div>
              ))
          )}
        </div>

        {smallPrint && !noJobs && (
          <Enter>
            <div className="careers__small-print">
              <Markdown source={smallPrint.description.description} />
            </div>
          </Enter>
        )}
      </div>

      {cta && (
        <Enter>
          <article className="cta-footer cta-footer--gray">
            <div className="cta-footer__content u-layout-max-width">
              <h3 className="cta-footer__title cta-footer__title--max-width">
                {cta.titleText}
              </h3>
              <Link to={cta.destination} className="button button-primary">
                {cta.linkText}
              </Link>
            </div>
          </article>
        </Enter>
      )}
    </section>
  )
}

export default CareersIndex
