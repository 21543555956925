import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Link from "@components/swipe-link"
import SEO from "@components/seo"
import Image from "@components/image"
import Button from "@components/button"
import Enter from "@components/animate-entry"
import HeroSection from "@components/hero-section"
import Markdown from "@components/markdown"

const AirspaceFairness = () => {
  const { contentfulPage } = useStaticQuery(graphql`
    query AirspaceFairness {
      contentfulPage(uid: { eq: "airspace-fairness" }) {
        pageDescription
        pageName
        sections {
          ... on ContentfulPageSection {
            uid
            id
            title
            description {
              description
            }
            image {
              title
              file {
                url
              }
            }
          }
        }
      }
    }
  `)

  const { sections, pageName, pageDescription } = contentfulPage
  const fairnessSection = sections.find(
    sect => sect.uid === "Understanding Fairness in Unmanned Traffic Management"
  )
  const openSkiesSection = sections.find(
    sect => sect.uid === "Open Skies For All"
  )

  return (
    <section className="AirspaceFairness">
      <SEO title={pageName} description={pageDescription} />

      <article className="Fairness-article hero-section">
        <div className="u-layout-max-width">
          <Enter>
            <h2 className="title">{fairnessSection.title}</h2>
          </Enter>
        </div>
        <div className="Fairness-image">
          <Enter>
            <div className="project-hero__image-wrap">
              <Image
                className="project-hero__image"
                {...fairnessSection.image}
                lazyDims={{ width: "100vw" }}
              />
            </div>
          </Enter>
        </div>

        <div className="u-layout-max-width">
          <Enter>
            <div className="Fairness-description">
              {fairnessSection && fairnessSection.description && (
                <Markdown source={fairnessSection.description.description} />
              )}
            </div>
          </Enter>
        </div>
      </article>

      <article className="OpenSkies-article">
        <div className="u-layout-max-width">
          <Enter>
            <div className="OpenSkies-description">
              {openSkiesSection && openSkiesSection.description && (
                <Markdown source={openSkiesSection.description.description} />
              )}
            </div>
          </Enter>
        </div>
      </article>
    </section>
  )
}

export default AirspaceFairness
