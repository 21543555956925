import kebabCase from "lodash.kebabcase"

// Takes a set of arguments. Filters out all the empty strings,
// and non-string and non-object arguments, accepts object keys with truthy values,
// and joins them into one className string.
// Ex: cn('hello', truthyVal && 'here', falsyVal && 'not-here', true, 2, '3', { another: 'true', nope: false })
//     -> 'hello here 3 another'
const cn = (...args) => {
  return args
    .reduce((array, arg) => {
      if (typeof arg === "string" && arg.length) {
        return [...array, arg]
      } else if (typeof arg === "object") {
        return [
          ...array,
          Object.keys(arg)
            .reduce(
              (arr, key) => (arg[key] ? [...arr, kebabCase(key)] : arr),
              []
            )
            .join(" "),
        ]
      } else {
        return array
      }
    }, [])
    .join(" ")
}

export default cn
