import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Link from "@components/swipe-link"
import SEO from "@components/seo"
import Image from "@components/image"
import Button from "@components/button"
import Enter from "@components/animate-entry"
import HeroSection from "@components/hero-section"
import Markdown from "@components/markdown"
import "./index.scss"

const NewDigitalEra = () => {
  const { contentfulPage } = useStaticQuery(graphql`
    query NewDigitalEra {
      contentfulPage(uid: { eq: "a-new-digital-era" }) {
        pageDescription
        pageName
        sections {
          ... on ContentfulPageSection {
            uid
            id
            title
            description {
              description
            }
            image {
              title
              file {
                url
              }
            }
          }
        }
      }
    }
  `)

  const { sections, pageName, pageDescription } = contentfulPage
  const trafficSection = sections.find(
    sect => sect.uid === "A Path Forward for Airspace & Traffic Management"
  )
  const aerospaceIndustrySection = sections.find(
    sect => sect.uid === "The aerospace industry is moving incredibly quickly"
  )
  const airbusSection = sections.find(
    sect => sect.uid === "Airbus and Boeing have jointly released"
  )
  const timeToActSection = sections.find(
    sect => sect.uid === "The time to act is now. "
  )

  return (
    <section className="NewDigitalEra">
      <SEO title={pageName} description={pageDescription} />

      <article className="aerospaceIndustrySection-article hero-section">
        <div className="u-layout-max-width">
          <Enter>
            <h2 className="title">{trafficSection.title}</h2>
          </Enter>

          <Enter>
            <div className="aerospaceIndustrySection-description">
              {aerospaceIndustrySection &&
                aerospaceIndustrySection.description && (
                  <Markdown
                    source={aerospaceIndustrySection.description.description}
                  />
                )}
            </div>
          </Enter>
        </div>
      </article>

      <article className="airbusSection-article">
        <div className="u-layout-max-width">
          <h2 className="title">{airbusSection.title}</h2>
          <Enter>
            <div className="section-content">
              <div className="section-left">
                <div className="airbusSection-description">
                  {airbusSection && airbusSection.description && (
                    <Markdown source={airbusSection.description.description} />
                  )}
                </div>
              </div>

              <div className="section-right">
                <Image className="image" {...airbusSection.image} />
              </div>
            </div>
          </Enter>
        </div>
      </article>

      <article className="timeToActSection-article">
        <div className="u-layout-max-width">
          <Enter>
            <h2 className="title">{timeToActSection.title}</h2>
          </Enter>

          <Enter>
            <div className="timeToActSection-description">
              {timeToActSection && timeToActSection.description && (
                <Markdown source={timeToActSection.description.description} />
              )}
            </div>
          </Enter>
          <Enter>
            <Image className="image" {...timeToActSection.image} />
          </Enter>
        </div>
      </article>
    </section>
  )
}

export default NewDigitalEra
