import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "@components/seo"

import Video from "@components/video"
import Enter from "@components/animate-entry"
import EnterAsRows from "@components/animate-row-entry"
import HeroSection from "@components/hero-section"
import Link from "@components/swipe-link"
import Image from "@components/image"
import { makePath } from "@utils/url"
import  MailChimpPage from "@components/mailchimp-page"

import "./index.scss"

const SubscribeMail = () => {
  const { contentfulPage } = useStaticQuery(graphql`
    query SubscribeMail {
      contentfulPage(uid: { eq: "subscribe-mail" }) {
        pageName
        pageDescription
        sections {
          ... on ContentfulPageSection {
            uid
            title
         
            image {
              title
              file {
                url
              }
            }
            cta {
              linkText
              destination
              description {
                description
              }
            }
            description {
              description
            }
            video {
              video {
                title
                file {
                  url
                  contentType
                }
              }
              posterImage {
                file {
                  url
                }
              }
            }
            content {
              ... on ContentfulEmployee {
                id
                name
                role
                bio {
                  bio
                }
                image {
                  file {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  // const { pageName, pageDescription, sections } = contentfulPage //Removed pageName from destructure

  // const heroSection = sections.find(sect => sect.uid === "about-us-hero")
  // const missionSection = sections.find(sect => sect.uid === "about-us-mission")
  // const leadershipSection = sections.find(s => s.uid === "about-us-leadership")

  return (
    <section className="about-us">
      {/* <SEO title={pageName} description={pageDescription} /> */}
      <SEO title="Acubed Mail" description="Acubed mail description" />
      <article className="about-us-hero">
        
      </article>
      <article className="about-us-mission  u-layout-max-width">
        <div class="subscribe-boxed">
        <Enter>
          <h2 className="about-us-mission__title">Subscribe to our newsletter</h2>
        </Enter>
        <Enter>
          <p className="body-large about-us-mission__desc">
          <MailChimpPage
         className='subscribe_form'
         /> 
          </p>
        </Enter>
        </div>
  
      </article>


    </section>
  )
}

export default SubscribeMail
