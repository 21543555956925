import React from "react"

import Image from "@components/image"
import Enter from "@components/animate-entry"
import Markdown from "@components/markdown"

import "./index.scss"

const HeroSection = ({ title, description, image, cta }) => (
  <section className="hero-section">
    <div className="hero-section__headline u-layout-max-width">
      <div className="hero-section__limit">
        <Enter>
          <h1 className="hero-section__title">{title}</h1>
        </Enter>
        {description && (
          <Enter delay={100}>
            <Markdown
              markdownClass="markdown--hero-section"
              renderers={{
                paragraph: props => {
                  return (
                    <p className="body-large hero-section__desc">
                      {props.children}
                    </p>
                  )
                },
              }}
              source={description.description || description}
            />
          </Enter>
        )}
      </div>
      {cta &&
        <div className="hero-section__cta">
          <a href={cta && cta.destination} className="button button-primary">{cta && cta.linkText}</a>
        </div>
      }
    </div>
    <Enter delay={200}>
      <div className="hero-section__image-wrap">
        <Image
          className="hero-section__image"
          file={image.file}
          title={image.title || title}
          w="1800"
          lazyDims={{ width: "100vw" }}
        />
      </div>
    </Enter>
  </section>
)

export default HeroSection
