import React, { useRef, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Flickity from "react-flickity-component"
import Helmet from "react-helmet"

import Image from "@components/image"
import SEO from "@components/seo"
import Link from "@components/swipe-link"
import PostCard from "@components/post-card"
import QuoteCarousel from "@components/quote-carousel"
import Enter from "@components/animate-entry"
import Button from "@components/button"
import { imageUrl } from "@utils/image"
import PlayIcon from "@images/play-circle-solid.svg"

import "./index.scss"

const Home = () => {
  const { contentfulPage, allContentfulBlogPost } = useStaticQuery(graphql`
    query MyQuery {
      contentfulPage(uid: { eq: "home" }) {
        pageName
        pageDescription
        sections {
          ... on ContentfulCta {
            __typename
            id
            linkText
            destination
            description {
              description
            }
          }
          ... on ContentfulPageSection {
            id
            subtitle
            title
            uid
            imageSet {
              file {
                url
              }
              title
              description
            }
            image {
              file {
                url
                contentType
              }
              title
            }
            description {
              description
            }
            cta {
              __typename
              titleText
              linkText
              destination
              description {
                description
              }
            }
            video {
              video {
                title
                file {
                  url
                  contentType
                }
              }
              posterImage {
                file {
                  url
                }
              }
            }
            content {
              ... on ContentfulQuote {
                id
                text {
                  text
                }
                publication
              }
            }
          }
        }
      }
      allContentfulBlogPost(sort: { fields: datePublished, order: DESC }) {
        nodes {
          title
          datePublished
          createdAt
          body {
            body
          }
          heroImage {
            file {
              url
            }
            title
          }
          project {
            name
            status
          }
        }
      }
    }
  `)

  const videoRef = useRef(null)
  const [playing, setPlaying] = useState(false)
  const [autoplayBlocked, setAutoplayBlocked] = useState(false)

  // Attempt to override autoplay block
  if (videoRef && videoRef.current && !playing) {
    const promise = videoRef.current.play()
    if (promise === undefined) {
      setAutoplayBlocked(true)
      setPlaying(true)
    } else {
      promise
        .then(() => {
          setPlaying(true)
        })
        .catch(err => {
          // Nothing
        })
    }
  }

  const { sections, pageName, pageDescription } = contentfulPage

  const heroVideo = sections.find(s => s.uid === "home-hero-video")
  const focus = sections.find(s => s.uid === "home-focus")
  // Add back to reinstate environment section
  const environment = null // sections.find(s => s.uid === "home-environment")
  const news = sections.find(s => s.uid === "home-news")
  const cta = sections.find(s => s.__typename === "ContentfulCta")

  const numbers = [focus, environment, news]
    .filter(sec => sec)
    .reduce(
      (obj, section, index) => ({
        ...obj,
        [section.uid]: index + 1,
      }),
      {}
    )

  const posts = allContentfulBlogPost.nodes.filter(
    p => (
      p.title
      && p.body
      && (
        p.project === null
        || p.project.status === 'Active'
      )
    )
  )
  const postsToShow = posts.slice(0, 4)

  return (
    <div className="home">
      <SEO title={pageName} description={pageDescription} />
      <Helmet>
        <meta
          name="google-site-verification"
          content="PB0pdnZsInZPjEbVeZs8a_OOBTlBqfCYC2psnbczh2U"
        />
      </Helmet>

      {heroVideo && (
        <section className="hero-video">
          {autoplayBlocked ? (
            <div className="video__play">
              <PlayIcon width="6vw" height="6vw" />
            </div>
          ) : null}
          <video
            ref={videoRef}
            muted="muted"
            loop="loop"
            playsInline
            className="video__content"
            preload="metadata"
            height="auto"
            width="100%"
            controls={autoplayBlocked}
            onClick={() => {
              if (autoplayBlocked) {
                setTimeout(() => {
                  setAutoplayBlocked(false)
                }, 50)
              } else {
                if (videoRef && videoRef.current) {
                  if (videoRef.current.paused) {
                    videoRef.current.play()
                  } else {
                    videoRef.current.pause()
                  }
                }
              }
            }}
            poster={imageUrl(heroVideo.video.posterImage.file.url, { q: 70 })}
          >
            <source
              src={`${heroVideo.video.video.file.url}#t=0.5`}
              type={heroVideo.video.video.file.contentType}
            />
            Your browser does not support the video tag.
          </video>
          {autoplayBlocked ? (
            <Image
              className="video__content"
              {...heroVideo.video.posterImage}
            />
          ) : null}
        </section>
      )}

      {focus && (
        <section className="numbered-article">
          <div className="numbered-article__content numbered-article__content--home-focus u-layout-max-width">
            <div className="numbered-article__limit">
              <Enter>
                <h4 className="numbered-article__category">
                  <span className="numbered-article__number">
                    {`0${numbers[focus.uid]}`}
                  </span>
                  {focus.title}
                </h4>
              </Enter>
              <Enter>
                <h3>{focus.subtitle}</h3>
              </Enter>
              <Enter>
                <p className="numbered-article__desc">
                  {focus.description.description}
                </p>
              </Enter>

              <Link
                to={focus.cta.destination}
                className="button button-primary"
              >
                {focus.cta.linkText}
              </Link>
            </div>
          </div>
          <Enter>
            <div className="hero-section__image-wrap">
              <Image
                className="hero-section__image"
                {...focus.image}
                w="1800"
                lazy
              />
            </div>
          </Enter>
        </section>
      )}

      {environment && (
        <section className="numbered-article numbered-article-centered u-layout-max-width">
          <article className="numbered-article__content">
            <Enter>
              <h4 className="numbered-article__category">
                <span className="numbered-article__number">
                  {`0${numbers[environment.uid]}`}
                </span>
                {environment.title}
              </h4>
            </Enter>
            <Enter>
              <h3>{environment.subtitle}</h3>
            </Enter>
            <Enter>
              <p className="numbered-article__desc">
                {environment.description.description}
              </p>
            </Enter>
            <Enter>
              <Button to={environment.cta.destination} type="tertiary" icon>
                {environment.cta.linkText}
              </Button>
            </Enter>
          </article>
        </section>
      )}

      {environment && environment.imageSet && environment.imageSet.length && (
        <Enter>
          <Flickity
            className="image-carousel"
            options={{
              wrapAround: true,
              pageDots: false,
              prevNextButtons: false,
              autoPlay: 2000, // Every 2 seconds
              pauseAutoPlayOnHover: false,
            }}
          >
            {environment.imageSet.map(image => (
              <Image
                className="image-carousel__image"
                {...image}
                key={image.title}
                w="800"
              />
            ))}
          </Flickity>
        </Enter>
      )}

      {news && (
        <section className="numbered-article numbered-article-full-width">
          <article className="numbered-article__content u-mb0 u-layout-max-width">
            <Enter>
              <h4 className="numbered-article__category">
                <span className="numbered-article__number">
                  {`0${numbers[news.uid]}`}
                </span>
                {news.title}
              </h4>
            </Enter>
            <h3 className="numbered-article__subtitle">{news.subtitle}</h3>

            <div className="home-news">
              <Enter>
                <div className="home-news__post-pair">
                  {postsToShow.slice(0, 2).map(post => (
                    <PostCard post={post} key={post.title} />
                  ))}
                </div>
              </Enter>
              <Enter>
                <div className="home-news__post-pair">
                  {postsToShow.slice(2).map(post => (
                    <PostCard post={post} key={post.title} />
                  ))}
                </div>
              </Enter>
              {news.content && news.content.length && (
                <Enter>
                  <QuoteCarousel quotes={news.content} />
                </Enter>
              )}
              <div className="home-news__button-wrap">
                <Button type="secondary" to="/news">
                  View All News
                </Button>
              </div>
            </div>
          </article>
        </section>
      )}
      {cta && (
        <article className="cta-footer cta-footer--gray">
          <div className="cta-footer__content u-layout-max-width">
            <h3 className="cta-footer__title cta-footer__title--max-width">
              {cta.description.description}
            </h3>
            <Link to={cta.destination} className="button button-primary">
              {cta.linkText}
            </Link>
          </div>
        </article>
      )}
    </div>
  )
}

export default Home
