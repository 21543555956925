import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

import Logo from "@images/a3-logo.svg"
import IconClose from "@images/icon-close.svg"
import IconHamburger from "@images/icon-hamburger.svg"
import Link from "@components/swipe-link"

import "./index.scss"

const Nav = () => {
  const [menuIsOpen, openMenu] = useState(false)
  const { contentfulNavSection } = useStaticQuery(graphql`
    query Header {
      contentfulNavSection(uid: { eq: "header" }) {
        linkBuckets {
          name
          links {
            text
            destination
          }
        }
      }
    }
  `)

  const { linkBuckets } = contentfulNavSection
  const leftLinks = linkBuckets.find(bucket => bucket.name === "Left Links")
  const rightLinks = linkBuckets.find(
    bucket => bucket.name === "Right Link Button"
  )

  return (
    <nav className="nav">
      <div className="nav__content">
        <Link to="/" reverse>
          <Logo className="nav__logo" />
        </Link>

        <article className="nav__left-links">
          {leftLinks.links.map(link => (
            <Link
              key={link.text}
              to={link.destination}
              activeClassName="active"
              className="nav__link"
            >
              {link.text}
            </Link>
          ))}
        </article>

        <article className="nav__right-links">
          {rightLinks.links.map(link => (
            <Link
              key={link.text}
              to={link.destination}
              onClick={() => {
                trackCustomEvent({
                  category: "Header Right Link",
                  action: "Click",
                  label: `${link.text} - ${link.destination}`,
                })
              }}
              activeClassName="active"
              className="button button-primary"
            >
              {link.text}
            </Link>
          ))}
          <IconHamburger
            className="nav__hamburger"
            onClick={() => openMenu(true)}
          />
        </article>

        <article className={`nav__dropdown ${menuIsOpen ? "is-open" : ""}`}>
          <div className="nav__dropdown-header">
            <Link to="/" reverse onClick={() => openMenu(false)}>
              <Logo className="nav__logo" />
            </Link>
            <IconClose className="nav__close" onClick={() => openMenu(false)} />
          </div>

          <div className="nav__dropdown-links">
            {[...leftLinks.links, ...rightLinks.links].map(link => (
              <Link
                key={link.text}
                to={link.destination}
                activeClassName="active"
                className="nav__dropdown-link"
                onClick={() => openMenu(false)}
              >
                {link.text}
              </Link>
            ))}
          </div>
        </article>
      </div>
    </nav>
  )
}

export default Nav
