import React, { useRef, useState } from "react"

import IconRightArrow from "@images/right-arrow.svg"
import IconLeftArrow from "@images/left-arrow.svg"
import Image from "@components/image"
import Markdown from "@components/markdown"

import "./index.scss"

const InternCarousel = ({ stories }) => {
  const [slideIndex, setSlideIndex] = useState(0)
  const [height, setHeight] = useState(null)

  const ref = useRef(null)

  if (ref && ref.current && !height) {
    const children = Array.from(ref.current.children)
    setHeight(
      Math.max(
        ...children.map(
          c =>
            (c &&
              c.getBoundingClientRect() &&
              c.getBoundingClientRect().height) ||
            0
        )
      )
    )
  }

  return (
    <section className="intern-carousel">
      <div className="intern-carousel__content">
       
        <div
          ref={ref}
          className="intern-carousel__stories"
          style={height ? { height: `${height}px` } : {}}
        >
          {stories.map((story, index) => (
            <article
              key={story.id}
              className={`intern-carousel__intern ${index === slideIndex ? "active" : ""
                }`}
            >
              
              <div className="intern-carousel_profile-info">
                <div className="intern-story-profile">
                <div className="intern-carousel__profile-image">
                  <Image className="internship__intern__icon" {...story.internProfileImage} lazy />
                </div>
                <div className="intern-carousel__profile-name">
                  <p><strong>Name: </strong>{story.name}</p>
                </div>
                <div className="intern-carousel__project">
                  <p><strong>Role at Acubed: </strong>{story.roleAtAcubed}</p>
                </div>
                <div className="intern-carousel__role">
                  <p><strong>Project: </strong>{story.project}</p>
                </div>
                </div>
                <div className="intern-carousel__story">
                  {/* <p>{story.internStories.internStories}</p> */}
                  <Markdown
                    markdownClass="markdown--hero-section"
                    renderers={{
                      paragraph: props => {
                        return (
                          <p className="body-large hero-section__desc">
                            {props.children}
                          </p>
                        )
                      },
                    }}
                    source={story.internStories.internStories}
                  />
                </div>
              </div>

            </article>
          ))}
        </div>
      </div>
        <div className="carsouel__controls">
        <div
          className="intern-carousel__nav left-arrow"
          onClick={() =>
            setSlideIndex(
              slideIndex - 1 < 0 ? stories.length - 1 : slideIndex - 1
            )
          }
        >
          
          <IconLeftArrow />
        </div>
      
      <div className="intern-carousel__dots">
        {stories.map((story, index) => (
          <div
            className={`intern-carousel__dot ${index === slideIndex ? "active" : ""
              }`}
            key={index}
            onClick={() => setSlideIndex(index)}
          >
            &bull;
          </div>
        ))}
      </div>
        <div
          className="intern-carousel__nav right-arrow"
          onClick={() => setSlideIndex((slideIndex + 1) % stories.length)}
        >
          <IconRightArrow />
        </div>
        </div>
    </section>
  )
}

export default InternCarousel
