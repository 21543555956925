import React from "react"

import Image from "@components/image"
import Markdown from "@components/markdown"

const AcubedLifeProfile = ({
  image,
  name,
  role,
  profileText,
  quote,
  quoteSpeaker,
}) => (
  <div className="acubed-life-profile u-layout-max-width">
    <div className="acubed-life-profile__image-wrap">
      <Image
        className="acubed-life-profile__image"
        {...image}
        w="400"
        h="400"
      />
      {quote && (
        <blockquote className="acubed-life-profile__quote">
          “{quote.quote}”
        </blockquote>
      )}
      {quoteSpeaker && (
        <p className="acubed-life-profile__quote-speaker">
          {quoteSpeaker.quoteSpeaker}
        </p>
      )}
    </div>
    <div className="acubed-life-profile__content">
      <h4 className="acubed-life-profile__role">{role}</h4>
      <p className="acubed-life-profile__name">{name}</p>

      <Markdown
        source={profileText.profileText}
        renderers={{
          // All headers are H4s in the employee profiles
          h1: "h4",
          h2: "h4",
          h3: "h4",
          h5: "h4",
          h6: "h5",
        }}
      />
    </div>
  </div>
)

export default AcubedLifeProfile
