import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "@components/seo"

import Video from "@components/video"
import Enter from "@components/animate-entry"
import EnterAsRows from "@components/animate-row-entry"
import HeroSection from "@components/hero-section"
import Link from "@components/swipe-link"
import Image from "@components/image"
import { makePath } from "@utils/url"

import "./index.scss"

const AboutUs = () => {
  const { contentfulPage } = useStaticQuery(graphql`
    query AboutUs {
      contentfulPage(uid: { eq: "about-us" }) {
        pageName
        pageDescription
        sections {
          ... on ContentfulPageSection {
            uid
            title
            imageSet {
              title
              file {
                url
              }
              description
            }
            image {
              title
              file {
                url
              }
            }
            cta {
              linkText
              destination
              description {
                description
              }
            }
            description {
              description
            }
            video {
              video {
                title
                file {
                  url
                  contentType
                }
              }
              posterImage {
                file {
                  url
                }
              }
            }
            content {
              ... on ContentfulEmployee {
                id
                name
                role
                bio {
                  bio
                }
                image {
                  file {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const { pageName, pageDescription, sections } = contentfulPage

  const heroSection = sections.find(sect => sect.uid === "about-us-hero")
  const missionSection = sections.find(sect => sect.uid === "about-us-mission")
  const leadershipSection = sections.find(s => s.uid === "about-us-leadership")

  console.log("mission", missionSection);

  return (
    <section className="about-us">
      <SEO title={pageName} description={pageDescription} />
      <article className="about-us-hero">
        <HeroSection
          title={heroSection.title}
          description={heroSection.description.description}
          image={heroSection.image}
        />
      </article>
      <article className="about-us-mission u-layout-max-width">
        <Enter>
          <h2 className="about-us-mission__title">{missionSection.title}</h2>
        </Enter>
        <Enter>
          <p className="body-large about-us-mission__desc">
            {missionSection.description.description}
          </p>
        </Enter>

        {/* mission icons */}
        <EnterAsRows delay={300}>
          <section className="mission-icons">
            {missionSection.imageSet.map(image => (
              <article key={image.file.url} className="mission-icons__content">
                <figure className="mission-icons__icon-wrap">
                  <Image className="mission-icons__icon" {...image} lazy />
                </figure>
                <p className="mission-icons__desc">{image.description}</p>
              </article>
            ))}
          </section>
        </EnterAsRows>
      </article>

      {missionSection.video && missionSection.video.video && (
        <Enter>
          <section className="video-wrap video-wrap--about-us u-layout-max-width">
            <Video
              url={missionSection.video.video.file.url}
              image={missionSection.video.video.posterImage}
              title={missionSection.video.video.title}
              contentType={missionSection.video.video.contentType}
            />
          </section>
        </Enter>
      )}

      <article className="about-us-leadership">
        <div className="about-us-leadership__employee-wrap u-layout-max-width">
          <Enter>
            <h2 className="about-us-leadership__title">
              {leadershipSection.title}
            </h2>
          </Enter>
          <p className="about-us-leadership__subtitle body-large">
            {leadershipSection.description.description}
          </p>
          <EnterAsRows delay={150}>
            <div className="about-us-leadership__employee-list">
              {leadershipSection.content.map((employee, index) =>
                employee.name && employee.image ? (
                  <Link
                    key={employee.name}
                    className="about-us-leadership__employee-card"
                    to={makePath.leader(employee)}
                  >
                    <figure className="about-us-leadership__img-wrap">
                      <Image
                        className="about-us-leadership__employee-photo"
                        {...employee.image}
                        title={employee.name}
                        w="400"
                        h="400"
                        lazyDims={{ height: "400px", width: "400px" }}
                        applyDefaultSettings={false}
                      />
                    </figure>

                    <div className="about-us-leadership__employee-info">
                      <h4 className="about-us-leadership__employee-name">
                        {employee.name}
                      </h4>
                      <p className="about-us-leadership__employee-role">
                        {employee.role}
                      </p>
                    </div>
                  </Link>
                ) : null
              )}
            </div>
          </EnterAsRows>
        </div>
      </article>
      <Enter>
        <article className="cta-footer">
          <div className="cta-footer__content u-layout-max-width">
            <Enter delay={100}>
              <h3 className="cta-footer__title">
                {leadershipSection.cta.description.description}
              </h3>
            </Enter>

            <Link
              to={leadershipSection.cta.destination}
              className="button button-primary"
            >
              {leadershipSection.cta.linkText}
            </Link>
          </div>
        </article>
      </Enter>
    </section>
  )
}

export default AboutUs
