import React from "react"
import get from "lodash.get"
import Helmet from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

import Link from "@components/swipe-link"
import SEO from "@components/seo"
import Markdown from "@components/markdown"
import { makeLocationString, removeHtml } from "@utils/string"
import { htmlDecode } from "@utils/dom"
import IconMarker from "@images/icon-marker.svg"
import IconArrowLeft from "@images/icon-arrow-left.svg"

import "./index.scss"

const Career = ({ pageContext }) => {
  const { job, acubedDescription } = pageContext
  const content = htmlDecode(job.content).replace(
    /style="font-weight: [0-9]+;"/g, // GH bolds whole paragraphs
    ""
  )

  const {
    site: { siteMetadata: meta },
  } = useStaticQuery(graphql`
    query CareerMeta {
      site {
        siteMetadata {
          description
          title
          siteUrl
          address
        }
      }
    }
  `)

  const text = removeHtml(content)
  const loc = makeLocationString(get(job, "location.name")) 

  const updatedAt = new Date(job.updated_at)

  const jsonLd = {
    "@context": "https://schema.org/",
    "@type": "JobPosting",
    title: job.title,
    hiringOrganization: {
      "@type": "Organization",
      name: meta.title,
      description: meta.description,
      url: meta.siteUrl,
      logo: `${meta.siteUrl}/icons/icon-144x144.png`,
    },
    description: content,
    datePosted: updatedAt.toISOString(),
    jobLocation: {
      "@type": "Place",
      address: meta.address,
    },
  }
  return (
    <section className="career">
      <SEO
        title={`${job.title} - Acubed by Airbus`}
        description={text.replace(/\n/g, " ")}
      />
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
      </Helmet>
      <article className="career-nav u-layout-max-width">
        <Link
          reverse
          to="/careers"
          className="button button-tertiary button-tertiary-left"
        >
          <div className="button-tertiary__content-wrap">
            <IconArrowLeft className="button-tertiary__icon" />
            <span className="button-tertiary__text">Careers</span>
          </div>
        </Link>
      </article>
      <article className="career-hero u-layout-max-text-width">
        <h1 className="h2 career-hero__title">{job.title}</h1>
        {get(job, "location.name") && (
          <p className="career-hero__location">
            <IconMarker
              className="career-hero__location-icon"
              width="19"
              height="24"
            />
            {loc}
          </p>
        )}
      </article>
      <article className="career-listing">
        <Markdown
          className="u-layout-max-text-width"
          source={acubedDescription}
        />
        <div
          className="u-layout-max-text-width markdown"
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
        <div className="u-layout-max-text-width career-listing__apply">
          <Link
            to={job.absolute_url}
            onClick={() => {
              trackCustomEvent({
                category: "Job App Link",
                action: "Click",
                label: job.absolute_url,
              })
            }}
            className="button button-primary"
          >
            Apply
          </Link>
        </div>
      </article>
    </section>
  )
}

export default Career
