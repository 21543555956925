import React, { useState } from 'react';

import IconMinus from "@images/minus.svg"
import IconPlus from "@images/plus.svg"

const Accordion = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false);

  const Icon = isActive ? IconMinus : IconPlus;

  return (
    <div className="accordion-item">
      <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
        <div className={`${isActive ? "active" : "inactive"}`}>
          <Icon />
        </div>
        <div className="title__wrap">{title}</div>
      </div>
      {isActive && <div className="accordion-content">{content}</div>}
    </div>
  );
};

export default Accordion;