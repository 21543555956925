import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Link from "@components/swipe-link"
import SEO from "@components/seo"
import Image from "@components/image"
import Button from "@components/button"
import Enter from "@components/animate-entry"
import HeroSection from "@components/hero-section"
import Markdown from "@components/markdown"

const CommunityPerception = () => {
  const { contentfulPage } = useStaticQuery(graphql`
    query CommunityPerception {
      contentfulPage(uid: { eq: "community-perception-study" }) {
        pageDescription
        pageName
        sections {
          ... on ContentfulPageSection {
            uid
            id
            title
            description {
              description
            }
            image {
              title
              file {
                url
              }
            }
          }
        }
      }
    }
  `)

  const { sections, pageName, pageDescription } = contentfulPage
  const UAMSection = sections.find(
    sect =>
      sect.uid ===
      "An Assessment of Public Perception of Urban Air Mobility (UAM) Section"
  )
  const aviationSection = sections.find(
    sect =>
      sect.uid ===
      "We stand at a thrilling point in the history of aviation Section"
  )

  return (
    <section className="CommunityPerception">
      <SEO title={pageName} description={pageDescription} />

      <div className="u-layout-max-width hero-section">
        <h2>{pageName}</h2>
      </div>

      <article className="CommunityPerception-article">
        <div className="u-layout-max-width">
          <Enter>
            <h2 className="title">{UAMSection.title}</h2>
          </Enter>
        </div>

        <div className="CommunityPerception-image">
          <Enter>
            <div className="project-hero__image-wrap">
              <Image
                className="project-hero__image"
                {...UAMSection.image}
                lazyDims={{ width: "100vw" }}
              />
            </div>
          </Enter>
        </div>

        <div className="u-layout-max-width">
          <Enter>
            <div className="CommunityPerception-description">
              {UAMSection && UAMSection.description && (
                <Markdown source={UAMSection.description.description} />
              )}
            </div>
          </Enter>
        </div>
      </article>

      <article className="aviationSection-article">
        <div className="u-layout-max-width">
          <Enter>
            <h2 className="title">{aviationSection.title}</h2>
          </Enter>

          <Enter>
            <Image className="image" {...aviationSection.image} />
          </Enter>

          <Enter>
            <div className="aviationSection-description">
              {aviationSection && aviationSection.description && (
                <Markdown source={aviationSection.description.description} />
              )}
            </div>
          </Enter>
        </div>
      </article>
    </section>
  )
}

export default CommunityPerception
