import React, { useState } from "react"
import Sensor from "react-visibility-sensor"
import { LazyLoadImage } from "react-lazy-load-image-component"

import Video, { getVideoFormat } from "@components/video"
import { imageUrl } from "@utils/image"

// If container dimensions are passed in for a lazy-loading image
// It will be an empty image of that size until it comes near view
// At which point the src will be loaded into the image.
const CustomLazyImage = ({ lazyDims: style, ...props }) => {
  const [seen, markSeen] = useState(false)
  return (
    <Sensor partialVisibility offset={{ top: 200 }}>
      {({ isVisible }) => {
        if (isVisible && !seen) {
          markSeen(true)
        }
        return isVisible || seen ? (
          // eslint-disable-next-line
          <img {...props} />
        ) : (
          // eslint-disable-next-line
          <img {...props} style={style} src="" />
        )
      }}
    </Sensor>
  )
}

/**
 * Smart image component
 * - Can take imgUrlProps, which are turned into querystring for contentful image API
 *   > Ignores all unusual props for svgs
 * - Can take lazy prop, which turns it into a lazy-loading image
 * - Can take lazyDims prop, for custom cases, which turns it into a lazy-loading image of particular dimensions
 * - Other props are normal
 *
 * Can also handle video files, which are rendered as videos
 */
const Image = ({ file, title, className, lazy, lazyDims, applyDefaultSettings, ...imgUrlProps }) => {
  let source = file.url.includes(".svg")
    ? file.url
    : imageUrl(file.url, imgUrlProps, applyDefaultSettings)

  if (getVideoFormat(file.url)) {
    return <Video url={file.url} title={title || file.filename || file.url} />
  }

  const props = {
    src: source,
    alt: title || file.filename || file.url,
    className,
  }

  if (lazy) {
    return <LazyLoadImage {...props} />
  } else if (lazyDims) {
    return <CustomLazyImage {...props} lazyDims={lazyDims} />
  } else {
    // eslint-disable-next-line
    return <img {...props} />
  }
}

export default Image
