import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "@components/seo"
import Enter from "@components/animate-entry"
import EnterAsRows from "@components/animate-row-entry"
import HeroSection from "@components/hero-section"
import Image from "@components/image"
import "./index.scss"
import InternCarousel from "../../components/intern-carousel"
import Accordion from "../../components/intern-faq-accordion/accordion"

const Internship = () => {

	const { contentfulPage } = useStaticQuery(graphql`
    query Internship {
      contentfulPage(uid: { eq: "internships" }) {
        pageName
        pageDescription
        sections {
          ... on ContentfulPageSection {
            uid
            title
			subtitle
            imageSet {
              title
              file {
                url
              }
              description
            }
            image {
              title
              file {
                url
              }
            }
            description {
                description
            }
						cta {
							linkText
							destination
						}
						content {
							... on ContentfulInternStories {
								id
								name
								internProfileImage {
									file {
										url
									}
								}
								roleAtAcubed
								project
								internStories {
									internStories
								}
							}
							... on ContentfulFaq {
								id
								question
								answer {
									answer
								}
							}
						}
          }

		... on ContentfulCta {
			id
			linkText
			destination
		  }
        }
      }
    }
  `)

	const { pageName, pageDescription, sections } = contentfulPage

	const heroSection = sections.find(sect => sect.uid === "internship-overview");
	const whoWeAre = sections.find(sect => sect.uid === "eligibility-criteria");
	const timelineSection = sections.find(sect => sect.uid === "application-process");
	const acubedInternSection = sections.find(sect => sect.uid === "acubed-intern");
	const internStoriesSection = sections.find(sect => sect.uid === "stories-from-our-interns");
	const internFaqSection = sections.find(sect => sect.uid === "faq");
	const cta = sections.find(sect => sect.__typename === "ContentfulCta")

	return (
		<section className="internship">
			<SEO title={pageName} description={pageDescription} />
			<article className="internship-hero">
				<HeroSection
					title={heroSection.title}
					description={heroSection.description.description}
					image={heroSection.image}
					cta={heroSection.cta}
				/>
			</article>

			<article className='internship__eligibility'>
				<div className='u-layout-max-width'>
					<Enter>
						<h2 className="internship__eligibility-title">{whoWeAre.title}</h2>
					</Enter>

					<EnterAsRows delay={300}>
						<section className="eligibility-icons">
							{whoWeAre.imageSet.map(image => (
								<article key={image.file.url} className="eligibility-icons__content">
									<figure className="eligibility-icons__icon-wrap">
										<Image className="eligibility-icons__icon" {...image} lazy />
									</figure>
									<p className="eligibility-icons__desc">{image.description}</p>
								</article>
							))}
						</section>
					</EnterAsRows>
				</div>
			</article>

			<article className="internship__timeline">
				<div className='u-layout-max-width'>
					<Enter>
						<h2 className="internship__timeline-title">{timelineSection.title}</h2>
					</Enter>

					<Enter>
						<p className="body-large internship__timeline__sub-title">
							{timelineSection.subtitle}
						</p>
					</Enter>
					<Enter>
						<figure className="timeline-image">
							<Image className="internship__intern__icon" {...timelineSection.image} lazy />
						</figure>
					</Enter>
				</div>
			</article>

			<article className="internship__acubed-intern">
				<div className='u-layout-max-width'>
					<Enter>
						<h2 className="internship__intern-title">{acubedInternSection.title}</h2>
					</Enter>

					<EnterAsRows delay={300}>
						<section className="internship__intern">
							{acubedInternSection.imageSet.map(image => (
								<article key={image.file.url} className="internship__intern__content">
									<figure className="internship__intern__icon-wrap">
										<Image className="internship__intern__icon" {...image} lazy />
									</figure>
									<div className="internship__intern__content__inner">
										<div className="internship__intern__content__wrap">
											<h3 className="internship__intern__title">{image.title}</h3>
											<p className="internship__intern__desc">{image.description}</p>
										</div>
									</div>
								</article>
							))}
						</section>
					</EnterAsRows>
				</div>
			</article>

			<article className="internship__stories">
				<div className='u-layout-max-width'>
					<Enter>
						<h2 className="internship__stories-title">{internStoriesSection.title}</h2>
					</Enter>
					<InternCarousel stories={internStoriesSection.content} />
				</div>
			</article>

			<article className="internship__faq">
				<div className='u-layout-max-width'>
					<Enter>
						<h2 className="internship__faq-title">{internFaqSection.title}</h2>
					</Enter>

					<Enter>
						<section className="internship__faq-content">
							<div className="internship__faq-col-left">
								{internFaqSection.content.map((faq, i) => (
									i < 6 ?
										<article key={faq.id} className="internship__faq-section">
											<Accordion title={faq.question} content={faq.answer.answer} />
										</article>
										: null
								))}
							</div>
							<div className="internship__faq-col-right">
								{internFaqSection.content.map((faq, i) => (
									i > 5 ?
										<article key={faq.id} className="internship__faq-section">
											<Accordion title={faq.question} content={faq.answer.answer} />
										</article>
										: null
								))}
							</div>
						</section>
					</Enter>
				</div>
			</article>
			{/* Cta section */}
			<article className="internship-cta">
				{cta &&
					<div className="hero-section__cta">
						<a href={cta && cta.destination} className="button button-primary">{cta && cta.linkText}</a>
					</div>
				}
			</article>

		</section>
	)
}

export default Internship;