import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Image from "@components/image"
import Markdown from "@components/markdown"
import SEO from "@components/seo"
import "./index.scss"

const Airbusutmlaanc = () => {
  const { contentfulPage } = useStaticQuery(graphql`
    query Airbusutmlaanc {
      contentfulPage(uid: { eq: "airbusutmlaanc" }) {
        pageName
        pageDescription
        sections {
          ... on ContentfulPageSection {
            uid
            title
			      subtitle
            imageSet {
              title
              file {
                url
              }
              description
            }
            image {
              title
              file {
                url
              }
            }
            content {
              ... on ContentfulPageSection {
                id
                title
                description {
                  description
                }
                cta {
                  linkText
                  destination
                }
                image {
                  file {
                    url
                  }
                }
              }
            }
            description {
                description
            }
            cta {
							linkText
							destination
						}
          }
        }
      }
    }
  `)

  const { sections, pageName, pageDescription } = contentfulPage
  const aerospaceSection = sections.find(sect => sect.uid === "airspace-authorizations");
  const forIndividualSection = sections.find(sect => sect.uid === "for-individuals");
  const forEnterpriseSection = sections.find(sect => sect.uid === "for-enterprises");
  const contactUsSection = sections.find(sect => sect.uid === "laanc-contact");

  return (
    <section className="laanc u-layout-max-width">
      <SEO title={pageName} description={pageDescription} />
      <article className="laanc-aerospace">
        <div className='u-layout-max-width'>
          <h1>{aerospaceSection.title}</h1>
          <h4>{aerospaceSection.subtitle}</h4>
          <figure className="laanc-image-wrap">
            <Image className="laanc-image" {...aerospaceSection.image} lazy alt={aerospaceSection.title} />
          </figure>
          <Markdown
            source={aerospaceSection.description.description}
          />
        </div>
      </article>

      <article className="laanc-individual">
        <div className='u-layout-max-width'>
          <h3>{forIndividualSection.title}</h3>
          <p>{forIndividualSection.subtitle}</p>
          <div>
            {forIndividualSection.content.map(content => (
              <article key={content.image.file.url} className="individual-content">
                <figure className="laanc-image-wrap">
                  <Image className="individual-image" {...content.image} lazy  />
                </figure>
                <div className="laanc-individual-content-inner">
                  <div className="laanc-individual-content-wrap">
                    <p className="laanc-individual-desc">{content.description.description}</p>
                    <div className="laanc-cta">
                      <a href={content.cta && content.cta.destination} target="_blank" className="button button-primary">{content.cta && content.cta.linkText}</a>
                    </div>
                  </div>
                </div>
              </article>
            ))}
          </div>
        </div>
      </article>

      <article className="laanc-enterprise">
        <div className='u-layout-max-width'>
          <h3>{forEnterpriseSection.title}</h3>
          <p>{forEnterpriseSection.subtitle}</p>
          <div>
            {forEnterpriseSection.content.map(content => (
              <article key={content.image.file.url} className="enterprise-content">
                <figure className="laanc-image-wrap">
                  <Image className="enterprise-image" {...content.image} lazy  />
                </figure>
                <div className="laanc-enterprise-content-inner">
                  <div className="laanc-enterprise-content-wrap">
                    <p className="laanc-enterprise-desc">{content.description.description}</p>
                    <div className="laanc-cta">
                      <a href={content.cta && content.cta.destination} target="_blank" className="button button-primary">{content.cta && content.cta.linkText}</a>
                    </div>
                  </div>
                </div>
              </article>
            ))}
          </div>
          <Markdown
            source={contactUsSection.description.description} />
        </div>
      </article>
    </section>
  )
}

export default Airbusutmlaanc