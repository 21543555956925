import React, { useEffect } from "react"
import jsonp from "jsonp"
import PropTypes from 'prop-types';
import "./index.scss"
import IconChevronDown from "@images/icon-chevron-down.svg"
//https://gmail.us7.list-manage.com/subscribe/post?u=161529024325685e851a4189d&amp;id=fdc5beaf0b
const MailChimpPage = ({
  className,
  page
}) => {

  return (
    <Mailchimps
      action='https://airbus.us7.list-manage.com/subscribe/post?u=bee38ee5878bc6d87711f9287&amp;id=97ea6c612e'
      fields={[
        {
          name: 'EMAIL',
          placeholder: 'Email',
          type: 'email',
          required: true
        },
        {
          name: 'FNAME',
          placeholder: 'Name',
          type: 'text',
          required: true
        },
        {
          name: 'Interested',
          id: 'INTERESTED',
          placeholder: 'Interested',
          type: 'select',
          options: [
            {
              'value': 'group[79681][1]_1',
              'text': 'Autonomous flight'
            },
            {
              'value': 'group[79681][2]_2',
              'text': 'Artificial intelligence/machine learning'
            },
            {
              'value': 'group[79681][4]_4',
              'text': 'Digital design and manufacturing'
            },
            {
              'value': 'group[79681][16]_16',
              'text': 'Unmanned Traffic Management'
            },
            {
              'value': 'group[79681][32]_32',
              'text': 'Future of mobility'
            },
            {
              'value': 'group[79681][64]_64',
              'text': 'Technology trends'
            },
            {
              'value': 'group[79681][128]_128',
              'text': 'Sustainability'
            },
            {
              'value': 'group[79681][256]_256',
              'text': 'Work life in Silicon Valley'
            },
            {
              'value': 'group[79681][512]_512',
              'text': 'Future of work'
            },
           
          ],
          required: true
        }
      ]}
      getSelectedData={[]}
      getValuesSelectedData={[]}
      className={className}
      page={page}
    />
  )

}

export default MailChimpPage


class Mailchimps extends React.Component {
  state = {};
  handleSubmit(evt) {
    evt.preventDefault();
    const { fields, action, getSelectedData,getValuesSelectedData,page } = this.props;
    var newArr=[];
    const values = fields.map(field => {
      if (field.type == 'text' || field.type == 'email') {
        return `${field.name}=${encodeURIComponent(this.state[field.name])}`;
      }
    }).join("&");
    if(getSelectedData.length > 0 ) {
    const valuesSelect = fields.map(field => {

      if (field.type == 'select') {
        
        getSelectedData.map((item,index) => {
          newArr.push({value: fields[2].options[item].value})
          
        })
        console.log('collectedData',newArr)
        var intsdArry = []
        for (var i = 0; i < newArr.length; i++) {
          if (typeof newArr[i].value !== 'undefined') {
            var splitArry = newArr[i].value.split('_')
            console.log(splitArry[0]);
            intsdArry.push({ 'field': splitArry[0], 'value': splitArry[1] })
            // intsdArry += `${splitArry[0]}=${encodeURIComponent(splitArry[1])}`;
          }
        }
        newArr=['']
        return intsdArry
      }
    });
    const dataIntres = valuesSelect.filter(element => {
      return element !== undefined;
    });

    const finalIntrstValue = dataIntres[0].map((getData, i) => {
      return `${getData.field}=${encodeURIComponent(getData.value)}`;
    }).join("&");

    const path = `${action}&${values + finalIntrstValue}`;
    console.log(path)
    const url = path.replace('/post?', '/post-json?');
    const regex = /^([\w_\.\-\+])+\@([\w\-]+\.)+([\w]{2,10})+$/;
    const email = this.state['EMAIL'];
    (!regex.test(email)) ? this.setState({ status: "empty" }) : this.sendData(url);
  } else {
    this.setState({ status: 'error' });
    this.setState({ msg: 'Please select your interest' })
  }
  };

  sendData(url) {
    const { fields, action, getSelectedData,getValuesSelectedData,page } = this.props;
    this.setState({ status: "sending" });
    jsonp(url, { param: "c" }, (err, data) => {
      console.log(data.result)
      console.log(data.msg)
      if (data.msg.includes("already subscribed")) {
        this.setState({ status: 'duplicate' });
        this.setState({ msg: data.msg })
      } else if (err) {
        this.setState({ status: 'error' });
        this.setState({ msg: data.msg })
      } else if (data.result !== 'success') {
        this.setState({ status: 'error' });
        this.setState({ msg: data.msg })
      } else {
        this.setState({ status: 'success' });
        this.setState({ msg: data.msg })
        document.getElementById('mailChimp').reset();
        setTimeout(() => {
          document.getElementById("text").value = '';
          document.getElementById("email").value = '';
          this.setState({ status: '' });
          getSelectedData.map(item => {
            document.getElementById("header_"+item).classList.remove("dropdown__item--selected");
          })
          document.getElementById('titleHdr').innerHTML = 'Select interests';
          while(getSelectedData.length > 0) {
            getSelectedData.pop();
        }
        }, 1000)

      };
    });
  }

  onTitleClick(ev) {
    var isDropdownOpen = document.getElementsByClassName('dropdown--open');
    var checkHdrDrp = document.getElementsByClassName('hdrOpen')
    if (isDropdownOpen.length > 0 && checkHdrDrp.length > 0) {
      document.getElementById("articleOpenHdr").classList.remove("dropdown--open");
      document.getElementById("articleOpenHdr").classList.remove("hdrOpen");
      console.log('titleClose header')
    } else {
      var d = document.getElementById("articleOpenHdr");
      d.className = "dropdown dropdown--open hdrOpen";
      console.log('titleOpen Header')
    }
  }

  onTitleClose(data) {
    console.log(data)
    document.getElementById("articleOpenHdr").classList.remove("dropdown--open");
    document.getElementById("articleOpenHdr").classList.remove("hdrOpen");

  }

  onItemClick(data, index) {
    const { fields, action, getSelectedData,getValuesSelectedData } = this.props;
    if (getSelectedData.indexOf(index) !== -1) {
      document.getElementById("header_"+index).classList.remove("dropdown__item--selected");
      const checkExist = getSelectedData.indexOf(index);
      if (checkExist > -1) {
        getSelectedData.splice(checkExist, 1);
      }
    } else {
      document.getElementById("header_"+index).classList.add("dropdown__item--selected");
      getSelectedData.push(index)
      
    }
      if(getSelectedData.length > 0){
     document.getElementById('titleHdr').innerHTML = 'Selected interests('+getSelectedData.length +')';
      } else {
        document.getElementById('titleHdr').innerHTML = 'Select interests';
      }
    console.log(getSelectedData)

  }
  render() {

    const { fields, styles, className, buttonClassName,page } = this.props;
    console.log(page)
    const messages = {
      ...Mailchimps.defaultProps.messages,
      ...this.props.messages
    }
    const { status, msg, isOpen } = this.state;
    return (
      <form onSubmit={this.handleSubmit.bind(this)} className={className} id="mailChimp">
        {fields.map(input => {
         
            if(input.type == 'text' || input.type == 'email')
           return <input id={input.type}
              {...input}
              key={input.name}
              onChange={({ target }) => this.setState({ [input.name]: target.value })}
              defaultValue={this.state[input.name]}
            />
            if (input.type == 'select')
            return <article id="articleOpenHdr" className="dropdown" key={input.options}>
             
            <div className="dropdown__title" onClick={() => this.onTitleClick(true)}>

            <span id="titleHdr">Select interests</span>
              <IconChevronDown className="dropdown__icon" />
        </div>

            <ul className="dropdown__list">
              {input.options.map((item, index) => (
                <li
                  className="dropdown__item"
                  id={'header_'+index}
                  key={item.value}
                  onClick={() => this.onItemClick(item.value, index)}
                >
                  <span>{item.text}</span>
                </li>
              ))}
            </ul>
          </article>
          
        }
        )}
        <button
          disabled={status === "sending" || status === "success"}
          type="submit"
          className={buttonClassName}
        >
          {messages.button}
        </button>
        <div className='msg-alert'>
          {status === "sending" && <p style={styles.sendingMsg}>{messages.sending}</p>}
          {status === "success" && <p style={styles.successMsg}>{messages.success}</p>}
          {status === "duplicate" && <p style={styles.duplicateMsg}>{messages.duplicate}</p>}
          {status === "empty" && <p style={styles.errorMsg}>{messages.empty}</p>}
          {status === "error" && <p style={styles.errorMsg}>{msg}</p>}
        </div>
      </form>
    );
  }
}

Mailchimps.defaultProps = {
  messages: {
    sending: "Sending...",
    success: "Thank you for subscribing!",
    error: "An unexpected internal error has occurred.",
    empty: "You must write an e-mail.",
    duplicate: "Too many subscribe attempts for this email address",
    button: "Subscribe"
  },
  buttonClassName: "",
  styles: {
    sendingMsg: {
      color: "#0652DD"
    },
    successMsg: {
      color: "#009432"
    },
    duplicateMsg: {
      color: "#EE5A24"
    },
    errorMsg: {
      color: "#ED4C67"
    }
  }
};

Mailchimps.propTypes = {
  action: PropTypes.string,
  messages: PropTypes.object,
  fields: PropTypes.array,
  styles: PropTypes.object,
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  getSelectedData: PropTypes.array,
  getValuesSelectedData: PropTypes.array,
};
