import React from "react"

import { formatDate } from "@utils/string"
import Link from "@components/swipe-link"

import "./index.scss"

const MediaCard = ({ publication, title, datePublished, url, className }) => (
  <Link className={`media-card ${className}`} to={url}>
    <span className="media-card__date label">{formatDate(datePublished)}</span>
    <span className="media-card__keyline"></span>
    <p className="body-large media-card__publication">{publication}</p>
    <h3 className="media-card__title">{title}</h3>
  </Link>
)

export default MediaCard
